import { CorporateMedia } from '@/types';
import { getData } from '@/utils/corpo/request/getData';
import { CorporateMediaEntity } from '@/modules/medias/domain/entities/CorporateMediaEntity';
import { corporateMediasRoute } from '@/modules/medias/domain/routes/mediaRoutes';

export const getMedias = async () => {
  try {
    const response = await getData<{ data: CorporateMedia[] }>(corporateMediasRoute());

    return (response?.data || []).map((d) => new CorporateMediaEntity(d).toMedia());
  } catch (e) {
    return [];
  }
};
