import { createContext } from 'react';

import { Notification } from '@/types/notifications';

export type NotificationsContextState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  notification: Notification;
};

export type NotificationsContextDispatch = {
  showNotificationError: (params?: string | Notification) => void;
  showNotificationInfo: (params?: string | Notification) => void;
  hideNotification: () => void;
  notification: Notification;
};

export const NotificationsContext = createContext<NotificationsContextState>(null);

export const NotificationsDispatchContext = createContext<NotificationsContextDispatch>(null);
