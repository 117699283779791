import { AuthEntity } from '@/modules/auth/domain/entity/AuthEntity';
import { coreLoginRoute } from '@/modules/auth/domain/routes/authRoutes';
import { UserEntity } from '@/modules/user/domain/entities/UserEntity';
import { requestQuery } from '@/services/request';
import { LoginRequest, LoginResponse } from '@/types';
import { setAuthSession } from '@/utils/browserStorage';

export const postSignin = async (data: LoginRequest) => {
  const response = await requestQuery<LoginResponse>({
    method: 'POST',
    url: coreLoginRoute,
    data,
    noAuth: true,
  });

  if (response) {
    await setAuthSession(new AuthEntity(response));

    return new UserEntity(response);
  }
};
