import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { playerRef, usePlayer, usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { useTrackPlayer } from '@/modules/analytic/hooks/useTrackPlayer';
import { FORWARD_INTERVAL } from '@/modules/player/providers/PlayerProvider.reducer';
import { useCallback } from 'react';

export const usePlayerGoBackward = () => {
  const { playedSeconds: seconds } = usePlayer();
  const dispatch = usePlayerDispatch();
  const { trackPlayerGoBackward } = useTrackPlayer();

  const handleBackward = useCallback(() => {
    if (!playerRef.current) return;

    const calculatedValue = seconds - FORWARD_INTERVAL < 1 ? 0 : seconds - FORWARD_INTERVAL;
    const playedSeconds = Math.trunc(calculatedValue);
    playerRef.current.seekTo(playedSeconds);

    dispatch({ type: PlayerReducerActions.HANDLE_BACKWARD, payload: { playedSeconds } });
    trackPlayerGoBackward();
  }, [dispatch, seconds, trackPlayerGoBackward]);

  return handleBackward;
};
