import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { playerRef, usePlayer, usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { useTrackPlayer } from '@/modules/analytic/hooks/useTrackPlayer';
import { FORWARD_INTERVAL } from '@/modules/player/providers/PlayerProvider.reducer';
import { useCallback } from 'react';

export const usePlayerGoForward = () => {
  const { playedSeconds: seconds } = usePlayer();
  const dispatch = usePlayerDispatch();
  const { trackPlayerGoForward } = useTrackPlayer();

  const handleForward = useCallback(() => {
    if (!playerRef.current) return;

    const playedSeconds = Math.trunc(seconds) + FORWARD_INTERVAL;
    playerRef.current.seekTo(playedSeconds);

    dispatch({ type: PlayerReducerActions.HANDLE_FORWARD, payload: { playedSeconds } });
    trackPlayerGoForward();
  }, [dispatch, seconds, trackPlayerGoForward]);

  return handleForward;
};
