import { DateTime } from 'luxon';

import { retrieveAuthSession } from '@/utils/browserStorage';

export const shouldRefreshToken = () => {
  const authSession = retrieveAuthSession();

  if (!authSession || authSession.refreshing) return false;

  if (!authSession.expiresAt) return true;

  const expire = DateTime.fromISO(authSession.expiresAt).toUTC();
  const now = DateTime.utc();
  return expire.diff(now, 'minutes').minutes <= 3;
};
