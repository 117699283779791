import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AnalyticsBrowser, SegmentEvent } from '@segment/analytics-next';
import { DateTime } from 'luxon';
import { useRouter } from 'next/router';
import { v1 as uuidv1 } from 'uuid';
import ky from 'ky';

import { useAuth } from '@/modules/auth/hooks/useAuth';
import {
  ANALYTICS_CATEGORIES,
  ANALYTICS_FACEBOOK_KEYS_MAP,
  ANALYTICS_KEYS,
  ANALYTIC_SESSION_TIME,
  EventSources,
  SessionEvent,
} from '@/types';
import { config, getEvent } from '@/utils';
import { AnalyticProviderProps, AnalyticProviderState } from './AnalyticProvider.types';

const analytics = AnalyticsBrowser.load({ writeKey: config.segmentApiKey });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendBatchEvent = (userUUID: string, eventName: string, eventAttributes: any) => {
  ky.post('/api/event', {
    json: { userUUID, eventName: `ue.${eventName}`, eventAttributes },
  }).catch((error) => {
    console.error(error);
  });
};

if (typeof window !== 'undefined') {
  analytics.on('track', async function (sessionEvent, properties) {
    const user = await analytics.user();
    const userUUID = user.id();

    sendBatchEvent(userUUID, sessionEvent.replace('.', '_'), properties);
  });

  analytics.on('page', async function (sessionEvent, properties, options) {
    const user = await analytics.user();
    const userUUID = user.id();

    sendBatchEvent(userUUID, properties?.replace('.', '_'), options);
  });
}

export const identify = async (userUUID: string | undefined, userInfo?: object) => {
  analytics.identify(userUUID, userInfo);
};

export const trackEvent = (name: ANALYTICS_KEYS, sessionEvent: SessionEvent) => {
  analytics.track(name as unknown as SegmentEvent, {
    timestamp: DateTime.now().toMillis().toString(),
    event_sender: 'webapp',
    ...sessionEvent,
  });
};

export const screen = (name: string, sessionEvent: SessionEvent) => {
  analytics.page(name, sessionEvent);
};

export const reset = () => {
  analytics.reset();
};

export const isCheckoutType = (sessionEvent: SessionEvent) => {
  return 'price' in sessionEvent;
};

export const AnalyticContext = createContext<AnalyticProviderState>({
  identify,
  track: trackEvent,
  computeAnalyticsTrackEvent: () => {},
  sessionUUID: null,
  lastEvent: null,
});

AnalyticContext.displayName = 'AnalyticContext';

export const AnalyticProvider = ({ children }: PropsWithChildren<AnalyticProviderProps>) => {
  const [sessionUUID, setSessionUUID] = useState(null);
  const [lastEvent, setLastEvent] = useState(null);
  const { user } = useAuth();
  const router = useRouter();

  const currentTimeStamps = DateTime.now().toMillis().toString();

  const createNewSession = Boolean(
    !lastEvent || Boolean(Number(currentTimeStamps) - Number(lastEvent) > ANALYTIC_SESSION_TIME)
  );

  useEffect(() => {
    if (!user?.UUID) return;

    identify(user.UUID);
  }, [user?.UUID]);

  useEffect(() => {
    if (sessionUUID) {
      trackEvent(ANALYTICS_KEYS.SESSION_NEW, {
        sessionUUID,
      });
    }
  }, [sessionUUID]);

  useEffect(() => {
    const { asPath } = router;
    const title = document.title;

    return () => {
      const event = getEvent(asPath);
      const programClickedOnCategory =
        event === 'CATEGORY_ONE' && window.history.state.as.split('/')[1] === 'programs';

      if (event) {
        screen(ANALYTICS_KEYS[`VIEW_${event}`], {
          sessionUUID: sessionUUID,
          ms_duration: DateTime.now().toMillis() - Number(currentTimeStamps),
          ...(event === 'HOME' && { source_home: EventSources.Home }),
          ...(event === 'CATEGORY_ONE' && {
            category: asPath.split('/')[2] as ANALYTICS_CATEGORIES,
            termination_reason: programClickedOnCategory ? 'clickProgram' : 'leave',
            programUUID: programClickedOnCategory[2],
          }),
          ...(event === 'PROGRAM_MAIN' && {
            programUUID: asPath.split('/')[1],
            program_title: title.split('- ')[1],
            termination_reason: 'leave',
          }),
        });
      }
    };
  }, [router]);

  const track = (command: ANALYTICS_KEYS, sessionEvent?: SessionEvent, withDuration = false) => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('TRACK', command /* sessionEvent */);
      return;
    }

    const payload = {
      timestamp: DateTime.now().toMillis().toString(),
      event_sender: 'webapp',
      sessionUUID: createNewSession ? uuidv1() : sessionUUID,
      ...(withDuration && {
        ms_duration: DateTime.now().toMillis() - Number(currentTimeStamps),
      }),
      ...sessionEvent,
    };

    trackEvent(command, payload);
    setSessionUUID(payload.sessionUUID);
    setLastEvent(payload.timestamp);
  };

  const computeAnalyticsTrackEvent = (
    eventKey: ANALYTICS_KEYS,
    category: ANALYTICS_CATEGORIES,
    sessionEvent: SessionEvent
  ): void => {
    const timestamp = DateTime.now().toMillis().toString();
    const data = {
      timestamp,
      event_sender: 'webapp',
      sessionUUID: sessionUUID,
      ...sessionEvent,
    };

    const value = (data?.price && data.price / 100) || 0;
    const currencyValue = data.currency?.toUpperCase();

    window.gtag('event', eventKey, {
      event_category: category,
      event_label: data.name,
      value,
      ...(sessionEvent && {
        ...data,
        currency: currencyValue,
        ...(isCheckoutType(sessionEvent) && {
          transaction_id: data.sessionUUID,
          items: [data.priceId],
        }),
      }),
    });

    const [, fbMapKey] = Object.entries(ANALYTICS_FACEBOOK_KEYS_MAP).find(
      ([key]) => key === eventKey
    ) || [null, eventKey];

    window.fbq(
      'track',
      fbMapKey,
      sessionEvent && {
        ...(isCheckoutType(sessionEvent) && {
          content_name: data.name,
          content_type: data.type,
          content_ids: [data.productId, data.priceId],
          num_items: data.quantity,
          currency: currencyValue,
          value: value.toFixed(2),
          content_category: category,
        }),
      }
    );
  };

  const analyticProviderState: AnalyticProviderState = {
    identify,
    track,
    computeAnalyticsTrackEvent,
    sessionUUID,
    lastEvent,
  };

  return (
    <AnalyticContext.Provider value={analyticProviderState}>{children}</AnalyticContext.Provider>
  );
};

export const useAnalyticContext = () => useContext(AnalyticContext);
