import { SSOTypes } from './auth';
import { Program } from './program';

export type UserInitializer = {
  UUID: string;
  avatarURL: string;
  birthdate: string;
  categories: string[];
  corpoJobCategory?: string;
  corpoHomePageID?: string;
  corpoDomainUUID?: string;
  createdAt: string;
  defaultPlaylistUUID: string;
  deleted: boolean;
  deletionComment: string;
  deletionDate: string;
  deletionReason: string;
  deviceID: string;
  email: string;
  emailApple: string;
  emailBO: string;
  /* @todo: remove emailFacebook when https://majelan.atlassian.net/browse/CONTENT-724 is completed */
  emailFacebook: string;
  emailGoogle: string;
  emailLink: string;
  enabled: boolean;
  expirationMd5: string;
  facebookID: string;
  firstName: string;
  gcu: boolean;
  hasBeenPremium: boolean;
  isTrial: boolean;
  lang: string;
  lastName: string;
  modifiedAt: string;
  newsletter: boolean;
  os: string;
  phoneNumber: string;
  plan: string;
  platform: string;
  premium: boolean;
  pushNotifications: boolean;
  refreshToken: string;
  role: string;
  signUpDate: string;
  signUpObjective: string;
  signUpPlatform: string;
  signUpType: SSOTypes;
  signUpUserAgent: string;
  stripeID: string;
  validated: boolean;
  validationMd5: string;
};

export interface User extends UserInitializer {
  isCorporate: boolean;
}

export type UserTokens = {
  UUID: string;
  authnDomainId?: string;
  corpoDomainUUID?: string;
  email?: string;
  firebaseToken: string;
  accessToken: string;
  refreshToken: string;
  expiresAt: string;
  refreshing?: boolean;
};

export enum RoleTypes {
  Persona = 'persona',
  Credit = 'credit',
}

export interface Role {
  UUID: string;
  name: string;
  avatarURL: string;
  description: string;
  type: RoleTypes;
  kind?: string;
  programs?: Program[];
  /**
   * @todo: check if order exists
   */
  order: number;
}
