import { requestQuery } from '@/services/request';
import { coreRolesRoute } from '../routes/userRoutes';
import { Role } from '@/types';

export const getRoles = async (teamUUID: string) => {
  try {
    const response = await requestQuery<Role[]>({
      url: coreRolesRoute,
      params: {
        filter: `${(teamUUID && `teamUUID:${teamUUID}&`) || ''}deleted:false&enabled:true`,
      },
    });

    return response;
  } catch (e) {
    return null;
  }
};
