import { getUser } from '@/modules/user/domain/resources';
import { requestQuery } from '@/services/request';
import { Query } from '@/types';
import { retrieveAuthSession } from '@/utils/browserStorage';

export const request: <T>(url: string, query?: Query) => Promise<T> = async (url, query) => {
  const session = retrieveAuthSession();
  let authnDomainId = session?.authnDomainId;

  if (!authnDomainId) {
    const user = await getUser(session?.UUID);
    authnDomainId = user?.corpoDomainUUID;
  }
  if (!authnDomainId) {
    /** @todo: Handle error */
    console.error('Missing authnDomainId', authnDomainId);
    return null;
  }

  return requestQuery({
    url: `corporate/content/${authnDomainId}/${url}`,
    ...query,
  });
};
