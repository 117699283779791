import { useQuery } from '@tanstack/react-query';

import { getMedias } from '@/modules/medias/domain/resources';

export const useMedias = (UUIDs: string[]) => {
  const { data, isFetching } = useQuery({
    queryKey: ['medias', ...UUIDs],
    queryFn: () => getMedias(UUIDs),
    enabled: UUIDs.length > 0,
    staleTime: Infinity,
  });

  return {
    medias: data,
    isLoadingMedias: isFetching,
  };
};
