/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
  interface Window {
    fbq: any;
    gtag: any;
    DD_RUM: any;
  }
}

export * from './analytics';
export * from './audio';
export * from './program';
export * from './user';
export * from './media';
export * from './category';
export * from './block';
export * from './subscription';
export * from './auth';
export * from './misc';
export * from './navigation';
export * from './domain';
export * from './request';
