import { Query } from '@/types';
import { requestQuery } from '@/services/request';

export const resolve: <T>(url: string) => Promise<T> = async (url) => {
  const query: Query = {
    url: `corporate/${url}`,
    noAuth: true,
  };

  return requestQuery(query);
};
