import classNames from 'classnames';
import { ReactNode, useCallback, useEffect } from 'react';

import { useClickOutside } from '@/hooks/useClickOutside';
import { useWindowSize } from '@/hooks/useWindowSize';
import { Breakpoints } from '@/types';

interface SideBarRightProps {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const SideBarRight = ({ children, isOpen, setIsOpen }: SideBarRightProps) => {
  const { screenWidth } = useWindowSize();

  const closeNavigation = useCallback(() => setIsOpen(false), [setIsOpen]);

  const sidebarRef = useClickOutside(closeNavigation);

  useEffect(() => {
    if (screenWidth < Breakpoints.Medium) {
      closeNavigation();
    }
  }, [screenWidth, closeNavigation]);

  return (
    <div className={classNames('sidebar', { open: isOpen })}>
      <aside ref={sidebarRef}>{children}</aside>
    </div>
  );
};
