import { OnProgressProps } from 'react-player/base';
import { Media, MediaHistory, Program } from '@/types';

export interface StartMediaProps {
  media: Media;
  autoPlay: boolean;
  lastTimeInMedia?: number;
  mediasInHistory?: MediaHistory[];
  isFromQueueList?: boolean;
}

export type PlayerReducerState = {
  mediaToPlay: StartMediaProps;
  playing: boolean;
  playbackURL: string;
  played: number;
  loaded: number;
  duration: number;
  seeking: boolean;
  playedSeconds: number;
  alreadyPlayed: number;
  remainingTime: number;
  canForward: boolean;
  canBackward: boolean;
  currentMedia: Media;
  currentProgram: Program;
  queueList: Media[];
  queueListDisplayed: boolean;
  lastTimeInMedia: number;
  snooze: boolean;
  speed: number;
  sound: number;
};

export enum PlayerReducerActions {
  SET_MEDIA = 'SET_MEDIA',
  SET_QUEUE_LIST = 'SET_QUEUE_LIST',
  SET_PROGRAM = 'SET_PROGRAM',
  SET_MEDIA_TO_PLAY = 'SET_MEDIA_TO_PLAY',
  SET_PLAYBACK_URL = 'SET_PLAYBACK_URL',
  HANDLE_BACKWARD = 'HANDLE_BACKWARD',
  HANDLE_DURATION = 'HANDLE_DURATION',
  HANDLE_FORWARD = 'HANDLE_FORWARD',
  HANDLE_PLAYING = 'HANDLE_PLAYING',
  HANDLE_PROGRESS = 'HANDLE_PROGRESS',
  HANDLE_SEEK_CHANGE = 'HANDLE_SEEK_CHANGE',
  HANDLE_SEEK_MOUSE_DOWN = 'HANDLE_SEEK_MOUSE_DOWN',
  HANDLE_SEEK_MOUSE_UP = 'HANDLE_SEEK_MOUSE_UP',
  HANDLE_START = 'HANDLE_START',
  HANDLE_STOP = 'HANDLE_STOP',
  HANDLE_BUFFERING = 'HANDLE_BUFFERING',
  HANDLE_SNOOZE = 'HANDLE_SNOOZE',
  HANDLE_SPEED = 'HANDLE_SPEED',
  HANDLE_DISPLAY_QUEUELIST = 'HANDLE_DISPLAY_QUEUELIST',
  HANDLE_SOUND = 'HANDLE_SOUND',
}

export interface PlayerReducerDispatch {
  type: PlayerReducerActions;
  progress: OnProgressProps;
  duration: number;
  media: Media;
  playbackURL: string;
  lastTimeInMedia: number;
  mediasInHistory: MediaHistory[];
  history: MediaHistory;
  queueList: Media[];
  played: number;
  playedSeconds: number;
  currentProgram: Program;
  speed: number;
  snoozeBlocked: boolean;
  sound: number;
}

export type PlayerReducerActionPayload =
  | {
      type: PlayerReducerActions.SET_MEDIA;
      payload: {
        currentMedia: Media;
        lastTimeInMedia: number;
      };
    }
  | { type: PlayerReducerActions.SET_QUEUE_LIST; payload: { queueList: Media[] } }
  | { type: PlayerReducerActions.SET_PROGRAM; payload: { currentProgram: Program } }
  | { type: PlayerReducerActions.SET_MEDIA_TO_PLAY; payload: { mediaToPlay: StartMediaProps } }
  | { type: PlayerReducerActions.SET_PLAYBACK_URL; payload: { playbackURL: string } }
  | { type: PlayerReducerActions.HANDLE_BACKWARD; payload: { playedSeconds: number } }
  | { type: PlayerReducerActions.HANDLE_DURATION; payload: { duration: number } }
  | { type: PlayerReducerActions.HANDLE_FORWARD; payload: { playedSeconds: number } }
  | { type: PlayerReducerActions.HANDLE_PROGRESS; payload: { progress: OnProgressProps } }
  | { type: PlayerReducerActions.HANDLE_SEEK_CHANGE; payload: { played: number } }
  | { type: PlayerReducerActions.HANDLE_BUFFERING; payload: { progress: OnProgressProps } }
  | { type: PlayerReducerActions.HANDLE_SNOOZE; payload: { snooze: boolean } }
  | { type: PlayerReducerActions.HANDLE_SPEED; payload: { speed: number } }
  | { type: PlayerReducerActions.HANDLE_SOUND; payload: { sound: number } }
  | {
      type: PlayerReducerActions.HANDLE_DISPLAY_QUEUELIST;
      payload: { queueListDisplayed: boolean };
    }
  | { type: PlayerReducerActions.HANDLE_SEEK_MOUSE_DOWN }
  | { type: PlayerReducerActions.HANDLE_SEEK_MOUSE_UP }
  | { type: PlayerReducerActions.HANDLE_PLAYING }
  | { type: PlayerReducerActions.HANDLE_START }
  | { type: PlayerReducerActions.HANDLE_STOP };
