import { useEffect } from 'react';

export const usePreventDropFile = () => {
  useEffect(() => {
    window.addEventListener(
      'dragover',
      (e: DragEvent) => {
        if ((e.target as HTMLLabelElement).htmlFor !== 'input-file-upload') e.preventDefault();
      },
      false
    );
    window.addEventListener(
      'drop',
      (e: DragEvent) => {
        if ((e.target as HTMLLabelElement).htmlFor !== 'input-file-upload') e.preventDefault();
      },
      false
    );
  }, []);
};
