import { PropsWithChildren, useEffect, useState } from 'react';

import { useAuthTokens } from '@/modules/auth/hooks/useAuthTokens';
import { useUser } from '@/modules/auth/hooks/useUser';
import { AuthContext } from '@/modules/auth/providers/AuthProvider.context';
import { retrieveAuthSession, retrieveUserSession } from '@/utils/browserStorage';

interface AuthProviderProps {}

export const AuthProvider = ({ children }: PropsWithChildren<AuthProviderProps>) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { authTokens, authTokensLoading } = useAuthTokens();
  const { user, userError, isLoadingUser } = useUser();
  const userSession = retrieveUserSession();
  const authSession = retrieveAuthSession();

  useEffect(() => {
    if (authTokens || authSession) {
      if (user || userSession) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }

    if (userError || !user) {
      setHasError(true);
      setIsLoading(false);
    }
  }, [user, userSession, authTokens, authSession, userError]);

  return (
    <AuthContext.Provider
      value={{
        user,
        error: hasError,
        isLoadingUser: isLoading || isLoadingUser || authTokensLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
