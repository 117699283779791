import { DateTime } from 'luxon';
import { useCallback, useRef } from 'react';
import { OnProgressProps } from 'react-player/base';

import { useTrackPlayer } from '@/modules/analytic/hooks/useTrackPlayer';
import { useAuth } from '@/modules/auth/hooks/useAuth';
import { usePlayer, usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { useFirebaseContext } from '@/providers/FirebaseAuthProvider';
import { ANALYTICS_KEYS, MediaHistory } from '@/types';

export const usePlayerProgress = () => {
  const { user } = useAuth();
  const lastFirebaseSaveTimestampRef = useRef(null);
  const { seeking, playing, currentMedia } = usePlayer();
  const dispatch = usePlayerDispatch();
  const { trackStreamPlay } = useTrackPlayer();
  const { saveMediaHistory } = useFirebaseContext();

  const handleProgress = useCallback(
    (progress: OnProgressProps) => {
      if (!user) return;

      if (!seeking) {
        dispatch({ type: PlayerReducerActions.HANDLE_PROGRESS, payload: { progress } });
      }

      if (!playing) {
        dispatch({ type: PlayerReducerActions.HANDLE_BUFFERING, payload: { progress } });
      }

      const now = DateTime.now();
      const differenceTimestampSeconds = Math.round(
        now / 1000 - lastFirebaseSaveTimestampRef.current / 1000
      );

      if (playing && differenceTimestampSeconds >= ANALYTICS_KEYS.STREAM_PLAY_INTERVAL) {
        lastFirebaseSaveTimestampRef.current = now;

        saveMediaHistory({
          ...currentMedia,
          lastTimeInMedia: progress.playedSeconds,
        } as unknown as MediaHistory);

        const timeInMedia = progress.playedSeconds;
        trackStreamPlay(timeInMedia);
      }
    },
    [currentMedia, dispatch, playing, seeking, saveMediaHistory, trackStreamPlay, user]
  );

  return handleProgress;
};
