import { format } from 'date-fns';
import { DateTime, Duration } from 'luxon';
import * as R from 'ramda';

import { DomainTypes } from '@/types';

export const getReadableTimeFromSec = (time: number) => {
  return format(time * 1000, 'mm:ss');
};

export const convertDurationMin = (
  duration: number,
  options: { withSeconds: boolean } = { withSeconds: true }
): string => {
  const milliseconds = duration * 1000;
  const { hours, minutes, seconds } = Duration.fromMillis(milliseconds)
    .shiftTo('hours', 'minutes', 'seconds')
    .toObject();

  return [
    ...[hours && hours + ' h'],
    ...[minutes && minutes + ' min'],
    ...[seconds && options.withSeconds && !hours && !minutes && Math.round(seconds) + ' sec'],
  ]
    .filter(Boolean)
    .join(' ');
};

export const getPublicationDateFormatted = (publicationDate: number): string => {
  if (!publicationDate) return '';

  const dateFormatted = DateTime.fromSeconds(publicationDate);
  const dateDiff = dateFormatted.diff(DateTime.now().startOf('day'), 'days').as('days');

  if (dateDiff > -1 && dateDiff < 2) {
    return dateFormatted.toRelativeCalendar();
  }

  return dateFormatted.toLocaleString(DateTime.DATE_MED);
};

export const getUrlExtension = (url: string) => url.split(/[#?]/)[0].split('.').pop().trim();

export const getCompletionDate = (lastCompletionDate: number) =>
  DateTime.fromSeconds(lastCompletionDate / 1000).toLocaleString(DateTime.DATE_MED);

export const serialize = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return !/(\{|\[)/.test(value) ? value : null;
  }
};

export const serializeMetadata = (metadata: object = {}) => {
  try {
    return Object.entries(metadata).reduce(
      (aggr, [key, value]) => ({
        ...aggr,
        [key]: serialize(value),
      }),
      {}
    );
  } catch (e) {
    return {};
  }
};

export const getEvent = (url: string) => {
  const data = url.split('/');
  data.shift();

  switch (data[0]) {
    case 'categories':
      return data[1] ? 'CATEGORY_ONE' : 'CATEGORY_MAIN';
    case 'library':
      return `LIBRARY_${data[1].toUpperCase()}`;
    case 'programs':
      return 'PROGRAM_MAIN';
    case 'account':
      return 'USER_PROFILE';
    case '':
      return 'HOME';
  }
};

export const passwordRuleLength = /(?=.{8,})/;
export const passwordRuleUppercase = /(?=.*[A-Z])/;
export const passwordRuleLowercase = /(?=.*[a-z])/;
export const passwordRuleNumber = /(?=.*[0-9])/;
export const passwordRuleSpecial = /(?=.*[^0-9a-zA-Z])/;

export const validatePasswordRule = (
  value: string,
  rule: 'length' | 'uppercase' | 'lowercase' | 'number' | 'special'
) => {
  switch (rule) {
    case 'length':
      return passwordRuleLength.test(value);

    case 'uppercase':
      return passwordRuleUppercase.test(value);

    case 'lowercase':
      return passwordRuleLowercase.test(value);

    case 'number':
      return passwordRuleNumber.test(value);

    case 'special':
      return passwordRuleSpecial.test(value);

    default:
      return false;
  }
};

export const getSourceFromMediaKeyId = (mediaKeyId: string = '') =>
  mediaKeyId?.split('::')[1] as DomainTypes;

export const getUUIDFromMediaKeyId = (mediaKeyId: string = '') => {
  const split = mediaKeyId?.split('::');
  return split.length > 1 ? (split[split.length - 1] as DomainTypes) : mediaKeyId;
};

export const getprogramUUIDFromMediaKeyId = (mediaKeyId: string = '') => {
  const split = mediaKeyId?.split('::');
  return split.length > 1 ? (split[split.length - 2] as DomainTypes) : mediaKeyId;
};

export const removeNils = <T>(o: T): T => {
  return R.pipe(
    R.reject(R.either(R.isNil, R.isEmpty)),
    R.map(R.when(R.both(R.is(Object), R.complement(R.is(Date))), removeNils)),
    R.reject(R.either(R.isNil, R.isEmpty))
  )(o) as T;
};
