import { useEffect, useRef } from 'react';
import Lottie from 'react-lottie-player';

import loaderPrimaryAnimation from '@/components/lottie/loader-remix.json';
import loaderSecondaryAnimation from '@/components/lottie/loader.json';

interface LoadingSpinnerProps {
  active: boolean;
  width?: number;
  height?: number;
  color?: 'primary' | 'secondary';
}

export const LoadingSpinnerIcon = ({
  active,
  width = 20,
  height = 20,
  color = 'primary',
}: LoadingSpinnerProps) => {
  const lottieRef = useRef(null);

  useEffect(() => {
    if (!lottieRef.current) return;

    if (active) {
      lottieRef.current.play();
    } else {
      lottieRef.current.stop();
    }
  }, [active]);

  return (
    <Lottie
      style={width && height ? { width, height } : {}}
      ref={lottieRef}
      animationData={color === 'primary' ? loaderPrimaryAnimation : loaderSecondaryAnimation}
      loop={true}
      data-testid="loading-spinner-icon"
    />
  );
};
