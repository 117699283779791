import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { ChangeEvent, useCallback } from 'react';

export const usePlayerSeekChange = () => {
  const dispatch = usePlayerDispatch();

  const handleSeekChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const played = parseFloat(e.target.value);

      dispatch({ type: PlayerReducerActions.HANDLE_SEEK_CHANGE, payload: { played } });
    },
    [dispatch]
  );

  return handleSeekChange;
};
