import { getUnixTime } from 'date-fns';

import { CorporateMediaEntity } from '@/modules/medias/domain/entities/CorporateMediaEntity';
import { CorporateMedia, CorporateProgram, DomainTypes, ProgramStatus, Typology } from '@/types';
import { Langs } from '@/types/lang';

import { ProgramEntity } from './ProgramEntity';

export class CorporateProgramEntity {
  source: DomainTypes;
  domain: string;
  uuid: string;
  name: string;
  description: string;
  coverPortrait: string;
  medias: CorporateMedia[];
  mediasCount: number;
  createdAt: Date;
  updatedAt: Date;

  constructor(programCorporate: CorporateProgram) {
    this.source = DomainTypes.Corporate;
    this.domain = programCorporate.domain;
    this.uuid = programCorporate.uuid;
    this.coverPortrait = programCorporate.coverPortrait;
    this.createdAt = new Date(programCorporate.createdAt);
    this.description = programCorporate.description;
    this.medias = programCorporate.medias;
    this.mediasCount = programCorporate.mediasCount;
    this.name = programCorporate.name;
    this.updatedAt = new Date(programCorporate.updatedAt);
  }

  toProgram() {
    return new ProgramEntity({
      source: DomainTypes.Corporate,
      domain: this.domain,
      UUID: this.uuid,
      cover: this.coverPortrait,
      description: this.description || '',
      programTypeKey: '',
      keyword1: '',
      lang: Langs.Fr,
      studio: '',
      syllabus: [],
      trailer: null,
      typology: Typology.Episodic,
      teamUUID: '',
      verticalPictureURL: this.coverPortrait,
      isFree: true,
      medias: (this.medias || []).map((media) => new CorporateMediaEntity(media).toMedia()),
      status: ProgramStatus.Run,
      title: this.name,
      endPublicationDate: null,
      publicationDate: getUnixTime(this.createdAt),
      modificationDate: getUnixTime(this.updatedAt),
    });
  }
}
