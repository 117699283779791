import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { usePlayer, usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { usePlayerStop } from './usePlayerStop';
import { usePlayerStartMedia } from './usePlayerStartMedia';
import { useMediasInHistoryByProgram } from '@/hooks/useMediasInHistoryByProgram';
import { useCallback } from 'react';

export const usePlayerEnded = () => {
  const { currentMedia, queueList } = usePlayer();
  const dispatch = usePlayerDispatch();
  const handleStop = usePlayerStop();
  const { startMedia } = usePlayerStartMedia();
  const { mediasInHistory } = useMediasInHistoryByProgram(currentMedia?.programUUID);

  const handleEnded = useCallback(async () => {
    const queueListWithoutEndedMedia = queueList?.filter(
      (media) => currentMedia?.UUID !== media.UUID
    );
    if (!queueListWithoutEndedMedia.length) {
      handleStop();

      return;
    }

    const nextMedia = queueListWithoutEndedMedia?.[0];

    dispatch({
      type: PlayerReducerActions.SET_QUEUE_LIST,
      payload: { queueList: queueListWithoutEndedMedia },
    });

    const history = mediasInHistory.find((history) => history.UUID === nextMedia?.UUID);

    startMedia({
      media: nextMedia,
      lastTimeInMedia: history?.lastTimeInMedia || 0,
      mediasInHistory: mediasInHistory || [],
      isFromQueueList: true,
      autoPlay: true,
    });
  }, [currentMedia, dispatch, handleStop, mediasInHistory, queueList, startMedia]);

  return handleEnded;
};
