import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { usePlayer, usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { Media } from '@/types';
import { useTrackQueueList } from '@/modules/analytic/hooks/useTrackQueueList';
import { useCallback } from 'react';

export const usePlayerRemoveFromQueueList = () => {
  const { queueList } = usePlayer();
  const dispatch = usePlayerDispatch();
  const { trackRemoveFromQueue } = useTrackQueueList();

  const removeFromQueueList = useCallback(
    async (media: Media) => {
      const oldQueueList = queueList ? queueList : [];

      const newQueueList = oldQueueList.filter(
        (mediaFromQueue) => mediaFromQueue.UUID !== media.UUID
      );

      dispatch({
        type: PlayerReducerActions.SET_QUEUE_LIST,
        payload: { queueList: newQueueList },
      });

      trackRemoveFromQueue({
        inQueue: queueList,
        removed: [media],
      });
    },
    [dispatch, queueList, trackRemoveFromQueue]
  );

  return removeFromQueueList;
};
