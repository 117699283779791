import { Langs } from './lang';
import { User, UserTokens } from './user';

export type AccountTypes = 'core' | 'corporate';

export type AuthActions = 'login' | 'signup';

export const oAuthTypesVariants = ['google', 'apple'] as const;

export type OAuthTypes = typeof oAuthTypesVariants[number];

export type SSOTypes = 'corporate' | 'majelan' | 'apple' | 'google' | 'kedge' | 'link' | 'sso';

export type QueryState = { sso: SSOTypes; type: AuthActions; url_from?: string };

export type LoginData = {
  id: string;
  name: string;
  oryProject: string;
  provider: string;
  loginHint: boolean;
  prompt: 'none' | 'consent' | 'login' | 'registration' | 'create' | 'select_account';
};

export type AccountDatas = {
  tokenID?: string;
  fbAccessToken?: string;
  token?: string;
  email?: string;
  password?: string;
  code?: string;
  type?: string;
  lang?: Langs;
};

export interface LoginRequest {
  accountDatas: AccountDatas;
  accountType: SSOTypes;
  gcu?: boolean;
  newsletter?: boolean;
  platform?: 'web';
  lang?: Langs;
  partnership?: boolean;
}

export interface LoginResponse extends User, UserTokens {
  email: string;
}

export interface ResolveResponse {
  type: 'corporate' | 'majelan' | null;
  domain?: LoginData;
}
