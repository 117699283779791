import { MediaStatus } from './media';
import { Typology } from './program';

export const ANALYTIC_SESSION_TIME = 300000; // in ms

export enum ANALYTICS_KEYS {
  SUBSCRIPTION_CHECKOUT = 'begin_checkout',
  SUBSCRIPTION_PURCHASE = 'purchase',
  SUBSCRIPTION_ADD_CART = 'add_to_cart',
  SUBSCRIPTION_FREE_SIGNUP = 'free_signup',
  SUBSCRIPTION_CLICK_SUBSCRIBE = 'click_subscribe_CTA',
  CONNEXION_REGISTRATION = 'sign_up',
  CLICK_CTA = 'click_CTA', // Unused
  ANDROID = 'android', // Unused
  IOS = 'ios', // Unused
  OPTIMIZE_ACTIVATE = 'optimize.activate', // Unused
  PLAY_TRAILER = 'play_trailer', // Unused
  CATALOG_FILTER_FORMAT = 'catalog_filter_format',
  PERSONALIZATION = 'signup.view_update_category_modal',
  PLAYER_BACKWARD = 'player.backward',
  PLAYER_FORWARD = 'player.forward',
  PLAYER_NEXT = 'player.next_media',
  PLAYER_PLAY = 'player.play',
  PLAYER_PAUSE = 'player.pause',
  PLAYER_SPEED = 'player.change_speed',
  PLAYER_SNOOZE = 'player.set_snooze',
  STREAM_PLAY = 'stream.play',
  STREAM_PLAY_INTERVAL = 5,
  CONTENT_REMOVE_FROM_PLAYLIST = 'content.remove_from_playlist',
  CONTENT_ADD_TO_PLAYLIST = 'content.add_to_playlist',
  QUEUE_ADD_MEDIAS = 'queue.add_medias',
  QUEUE_REMOVE_MEDIAS = 'queue.remove_medias',
  QUEUE_SEE_QUEUE = 'queue.see_queue',
  QUEUE_REORDER_QUEUE = 'queue.reorder_queue',
  SESSION_NEW = 'session.new',
  SEARCH = 'search.search',
  SHARE_CONTENT = 'content.share_content',
  BROWSE_VIEW_PROGRAM_MAIN = 'browse.view_program_main',
  BROWSE_VIEW_CATEGORY_ONE = 'browse.view_category_one',
  BROWSE_VIEW_BLOCK = 'browse.view_block',
  BROWSE_VIEW_CONTENT = 'browse.view_content',
  BROWSE_CLICK_CONTENT = 'browse.click_content',
  BROWSE_VIEW_HOME = 'browse.home',
  BROWSE_VIEW_CATEGORY_MAIN = 'browse.view_category_main',
  BROWSE_VIEW_LIBRARY_RESUME = 'browse.view_library_resume',
  BROWSE_VIEW_LIBRARY_BOOKMARKED = 'browse.view_library_favorites',
  BROWSE_VIEW_LIBRARY_HISTORY = 'browse.view_library_history',
  BROWSE_VIEW_USER_PROFILE = 'browse.view_user_profile',
  BROWSE_VIEW_PLAYER = 'browse.player',
}

export enum ANALYTICS_LABEL {
  SUBSCRIPTION_FREE_SIGNUP = 'free',
  SUBSCRIPTION_CLICK_SUBSCRIBE_HOME_BANNER = 'home_hero',
  SUBSCRIPTION_CLICK_SUBSCRIBE_HEADER = 'nav_header',
  SUBSCRIPTION_CLICK_SUBSCRIBE_MY_ACCOUNT_FREE = 'my_account_free',
  BANNER_MARKET_HOME = 'banner_market_home',
  BANNER_GIFT_OFFERS = 'banner_gift_offers',
  WEBAPP_COMING_SOON = 'webapp_coming_soon',
  THANK_YOU_PAGE = 'thank_you_page',
  PUSH_NOTIF = 'push_notif',
  WEB_APP_PAGE_COMING_SOON = 'webapp_page_coming_soon',
  ACTIVATION_COUPON = 'activation_coupon',
  PROGRAM_PAGE = 'program_page',
  PROMO_EXPIRED = 'promo_expired',
  PERSONALIZATION = 'signup.view_update_category_modal',
}

export enum ANALYTICS_CATEGORIES {
  SUBSCRIPTION = 'subscription',
  CONNEXION = 'connexion',
  MARKETING = 'marketing',
  DOWNLOAD = 'download',
  PRODUCT_USE = 'Product use',
}

export enum ANALYTICS_FACEBOOK_KEYS {
  SUBSCRIPTION_CHECKOUT = 'InitiateCheckout',
  SUBSCRIPTION_PURCHASE = 'Purchase',
  SUBSCRIPTION_ADD_CART = 'AddToCart',
  SUBSCRIPTION_FREE_SIGNUP = 'FreeSignUp',
  CONNEXION_REGISTRATION = 'CompleteRegistration',
}

export const ANALYTICS_FACEBOOK_KEYS_MAP = {
  [ANALYTICS_KEYS.SUBSCRIPTION_CHECKOUT]: ANALYTICS_FACEBOOK_KEYS.SUBSCRIPTION_CHECKOUT,
  [ANALYTICS_KEYS.SUBSCRIPTION_PURCHASE]: ANALYTICS_FACEBOOK_KEYS.SUBSCRIPTION_PURCHASE,
  [ANALYTICS_KEYS.SUBSCRIPTION_ADD_CART]: ANALYTICS_FACEBOOK_KEYS.SUBSCRIPTION_ADD_CART,
  [ANALYTICS_KEYS.CONNEXION_REGISTRATION]: ANALYTICS_FACEBOOK_KEYS.CONNEXION_REGISTRATION,
  [ANALYTICS_KEYS.SUBSCRIPTION_FREE_SIGNUP]: ANALYTICS_FACEBOOK_KEYS.SUBSCRIPTION_FREE_SIGNUP,
};

export type SessionEvent = {
  category?: ANALYTICS_CATEGORIES;
  label?: ANALYTICS_LABEL;
  picture_url?: string;
  source_home?: string;
  source?: string;
  contentOrigin?: string;
  domainID?: string;
  media_duration?: number;
  content_type?: string;
  destination?: string;
  context?: string;
  blockUUID?: string;
  block_title?: string;
  block_type?: string;
  block_format?: string;
  name?: string;
  type?: string;
  productId?: string;
  quantity?: number;
  priceId?: string;
  price?: number;
  currency?: string;
  mediaUUIDs_in_queue?: string[];
  mediaUUIDs_removed_queue?: string | string[];
  mediaUUIDs_added_queue?: string | string[];
  end_of_queue?: boolean | 'true' | 'false';
  min_duration?: string;
  selected_speed?: number;
  media_title?: string;
  mediaIdentifierKey?: string;
  mediaUUID?: string;
  ms_duration?: number;
  target_mediaUUID?: string;
  target_media_title?: string;
  target_programUUID?: string;
  target_program_title?: string;
  programUUID?: string;
  program_title?: string;
  playlist_title?: string;
  time_in_media?: number;
  media_order?: number;
  media_max_position?: number;
  media_position?: number;
  media_status?: MediaStatus;
  userUUID?: string;
  is_premium?: 1 | 0;
  program_typology?: Typology;
  typed_query?: string;
  termination_reason?: string;
  contentUUID?: string;
  corpoJobCategory?: string;
  categories?: string[];
  has_reset?: boolean;
  sessionUUID?: string;
};

export enum ContentTypes {
  Program = 'program',
  Ad = 'ad',
}

export enum EventSources {
  Home = 'home',
  Category = 'category',
  Player = 'player',
}

export enum TerminationReason {
  Completed = 'completed',
  Leave = 'LEAVE',
}
