import classNames from 'classnames';
import Link from 'next/link';
import { ReactNode } from 'react';

import { LoadingSpinnerIcon } from '@/components/icons/LoadingSpinnerIcon';

export enum ButtonStyles {
  'primary',
  'secondary',
  'link',
  'danger',
  'default',
  'light',
}

export enum ButtonTypes {
  Button = 'button',
  Reset = 'reset',
  Submit = 'submit',
}

interface ButtonProps {
  style?: keyof typeof ButtonStyles;
  type?: ButtonTypes;
  iconOnly?: boolean;
  onClick?: (e?) => void;
  active?: boolean;
  ariaLabel?: string;
  ariaControls?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  children?: ReactNode | string;
  link?: string;
  isLoading?: boolean;
}

export const Button = ({
  style = 'default',
  type = ButtonTypes.Button,
  iconOnly = false,
  onClick = () => {},
  active = null,
  ariaLabel = null,
  ariaControls = null,
  disabled = false,
  fullWidth = false,
  isLoading = false,
  className = '',
  link,
  children,
}: ButtonProps) => {
  const getStyle = () => {
    return classNames(
      `btn btn-${style}`,
      { 'btn-primary': style === 'primary' },
      { 'btn-danger': style === 'danger' },
      { 'btn-light': style === 'light' },
      { 'btn-loading': isLoading },
      { 'px-5': !iconOnly },
      { 'aspect-square border-none px-2 icon-only': iconOnly },
      { 'w-full': fullWidth },
      { active }
    );
  };

  return (
    <>
      {!link ? (
        <button
          type={type}
          className={`${getStyle()} ${className}`}
          onClick={onClick}
          aria-label={ariaLabel}
          aria-controls={ariaControls}
          disabled={disabled}
        >
          {isLoading ? <LoadingSpinnerIcon active /> : children}
        </button>
      ) : (
        <Link
          href={link}
          className={`${getStyle()} ${className}`}
          onClick={onClick}
          aria-label={ariaLabel}
          aria-controls={ariaControls}
        >
          {children}
        </Link>
      )}
    </>
  );
};
