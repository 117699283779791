import { useQuery } from '@tanstack/react-query';

import { getAudios } from '@/modules/audio/domain/resources/getAudios';
import { Audio } from '@/types';

export const useAudiosByUUIDS = (UUIDs: string[]) => {
  const { data, isLoading } = useQuery({
    queryKey: ['audio', ...UUIDs],
    queryFn: () => getAudios(UUIDs),
    enabled: UUIDs.length > 0,
    staleTime: Infinity,
  });

  return {
    audios: data as Audio[],
    isLoadingAudios: isLoading,
  };
};
