import { useMemo } from 'react';

import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { usePlayerSeekChange } from '@/modules/player/providers/dispatch/usePlayerSeekChange';
import { usePlayerSeekMouseDown } from '@/modules/player/providers/dispatch/usePlayerSeekMouseDown';
import { usePlayerSeekMouseUp } from '@/modules/player/providers/dispatch/usePlayerSeekMouseUp';
import { getReadableTimeFromSec } from '@/utils';

export const ProgressBar = () => {
  const { loaded, played, alreadyPlayed, remainingTime, currentMedia } = usePlayer();
  const handleSeekChange = usePlayerSeekChange();
  const handleSeekMouseDown = usePlayerSeekMouseDown();
  const handleSeekMouseUp = usePlayerSeekMouseUp();
  const max = useMemo(() => (currentMedia ? 1 : 0), [currentMedia]);

  return (
    <div className="progressbar">
      <div className="w-full relative md:mt-2 mt-0">
        <div className="progress-bar-loaded" style={{ width: `${(loaded * 100) / 1 || 0}%` }} />
        <div className="progress-bar-played" style={{ width: `${(played * 100) / 1 || 0}%` }} />
        <input
          type="range"
          value={played}
          max={max}
          step="any"
          onChange={handleSeekChange}
          onMouseDown={handleSeekMouseDown}
          onMouseUp={handleSeekMouseUp}
          className="md:progress-bar progress-bar-mobile"
          disabled={!currentMedia}
        />
      </div>
      <div className="justify-between pt-4 w-full md:progress-time progress-time-mobile">
        <label className="text-sm leading-none text-brand-neutral-300">
          {getReadableTimeFromSec(currentMedia ? alreadyPlayed : 0)}
        </label>
        <label className="text-sm leading-none text-brand-neutral-300">
          {getReadableTimeFromSec(currentMedia ? remainingTime : 0)}
        </label>
      </div>
    </div>
  );
};
