import { DomainTypes } from './domain';
import { CorporateMedia, Media } from './media';

export enum ProgramSource {
  Majelan = 'majelan',
  Radioline = 'radioline',
  Nemo = 'nemo',
}

export interface ProgramInitializer {
  source: DomainTypes;
  domain: string;
  UUID: string;
  verticalPictureURL: string;
  cover?: string;
  description: string;
  medias: Media[];
  programTypeKey: string;
  keyword1: string;
  lang: string;
  status: ProgramStatus;
  studio: string;
  syllabus: string[];
  title: string;
  trailer: Trailer;
  typology: Typology;
  isFree?: boolean;
  teamUUID?: string;
  creationDate?: number;
  modificationDate?: number;
  endPublicationDate: number;
  publicationDate?: number;
  enabled?: boolean;
  deleted?: boolean;
}

export interface Program extends ProgramInitializer {
  mediaKeyId: string;
  isAvailable: boolean;
  isCorporate: boolean;
  route: string;
  cover: string;
}

export interface Trailer {
  UUID: string;
  duration: number;
  endPublicationDate: number;
  isFree: true;
  lang: string;
  publicationDate: number;
  title: string;
}

export interface Playlist {
  UUID: string;
  powertips: [];
  programs: Program[];
  title: string;
}

export interface ProgramFollow {
  createdAt: string;
  domainId: string;
  id: string;
  programId: string;
  type: string;
}

export type ProgramFollows = ProgramFollow[];

export enum ProgramStatus {
  ComingSoon = 'comingSoon',
  New = 'new',
  Run = 'run',
  Unprocessed = 'unprocessed',
  premium = 'premium',
  newEpisodes = 'newEpisodes',
  scheduled = 'scheduled',
  offline = 'offline',
  free = 'free',
  error = 'error',
}

export interface Trailer {}

export enum Typology {
  Episodic = 'episodic',
  Editorial = 'editorial',
  Linear = 'linear',
  Serial = 'serial',
}

export type CorporateProgram = {
  domain: string;
  source: string;
  uuid: string;
  name: string;
  description: string;
  coverLandscape: string;
  coverPortrait: string;
  medias: CorporateMedia[];
  mediasCount: number;
  createdAt: Date;
  updatedAt: Date;
};

export type CorporateProgramWithOrder = {
  order: number;
  program: CorporateProgram;
};
