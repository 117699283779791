import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { usePlayer, usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { useTrackPlayer } from '@/modules/analytic/hooks/useTrackPlayer';
import { usePlayerStartMedia } from './usePlayerStartMedia';
import { useMediasInHistoryByProgram } from '@/hooks/useMediasInHistoryByProgram';
import { useCallback } from 'react';

export const usePlayerPlayNext = () => {
  const { currentMedia, queueList, currentProgram } = usePlayer();
  const dispatch = usePlayerDispatch();
  const { trackPlayerGoNext } = useTrackPlayer();
  const { startMedia } = usePlayerStartMedia();
  const { mediasInHistory } = useMediasInHistoryByProgram(currentProgram?.UUID);

  const handlePlayNext = useCallback(() => {
    const queueListWithoutCurrentMedia = queueList?.filter(
      (media) => currentMedia?.UUID !== media.UUID
    );

    if (!queueListWithoutCurrentMedia.length) return;

    const nextMedia = queueListWithoutCurrentMedia[0];

    const nextMediaInHistory = mediasInHistory.find((mediaH) => mediaH.UUID === nextMedia?.UUID);

    dispatch({
      type: PlayerReducerActions.SET_QUEUE_LIST,
      payload: { queueList: queueListWithoutCurrentMedia },
    });

    startMedia({
      media: nextMedia,
      lastTimeInMedia: nextMediaInHistory?.lastTimeInMedia || 0,
      mediasInHistory: mediasInHistory || [],
      isFromQueueList: true,
      autoPlay: true,
    });

    trackPlayerGoNext(nextMedia);
  }, [currentMedia, dispatch, mediasInHistory, queueList, startMedia, trackPlayerGoNext]);

  return handlePlayNext;
};
