import { useRouter } from 'next/router';

import { Player as PlayerWrapper } from './organisms/Player';
import classNames from 'classnames';
import { isOnAuth } from '@/utils/routes';

export const Player = () => {
  const { pathname } = useRouter();

  return (
    <div className={classNames({ ' hidden': isOnAuth(pathname) })}>
      <PlayerWrapper />
    </div>
  );
};
