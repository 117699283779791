import { useRouter } from 'next/router';
import { createContext, useContext, useEffect } from 'react';

import { useAuth } from '@/modules/auth/hooks/useAuth';
import { useLogin } from '@/modules/auth/hooks/useLogin';
import { useSignup } from '@/modules/auth/hooks/useSignup';
import { getQueryState, splitStateString } from '@/modules/auth/utils/getQueryState';
import {
  callbackRoute,
  discoverRoute,
  homeRoute,
  isOnAuth,
  loginRoute,
  proRoute,
  proRoutes,
} from '@/utils/routes';

export type RedirectContextState = {
  handleRedirect: () => void;
};

export const RedirectContext = createContext<RedirectContextState>(null);

interface RedirectContextProps {
  children?: React.ReactNode;
}

export const RedirectProvider = ({ children }: RedirectContextProps) => {
  const { user, isLoadingUser, error } = useAuth();
  const { isLoadingLogin } = useLogin();
  const { isLoadingSignup } = useSignup();
  const { replace, query, isReady, pathname, asPath } = useRouter();

  useEffect(() => {
    if (!isReady || isLoadingUser || isLoadingLogin || isLoadingSignup) {
      return;
    }
    if (user) {
      if (isOnAuth(pathname) || pathname === homeRoute) {
        const state =
          pathname === callbackRoute
            ? getQueryState().state
            : splitStateString(query.return_to as string);

        const url = (state?.url_from || '').split('?')?.[0] || '';
        const searchParams = new URLSearchParams((state?.url_from || '').split('?')?.[1] || '');
        const newPathname = url && url !== '/' ? url : user?.isCorporate ? proRoute : discoverRoute;
        const queryFrom = Object.fromEntries(searchParams.entries());

        replace({
          pathname: newPathname,
          query: { ...queryFrom, ...query },
        });
        return;
      }

      if (!user.isCorporate && proRoutes.includes(pathname)) {
        replace({
          pathname: discoverRoute,
          query,
        });
        return;
      }
    } else {
      if (!isOnAuth(pathname)) {
        replace({
          pathname: loginRoute,
          query: { ...query, url_from: encodeURI((query.url_from || asPath) as string) },
        });
        return;
      }
    }
  }, [
    error,
    user,
    isReady,
    pathname,
    query,
    isLoadingUser,
    asPath,
    replace,
    isLoadingLogin,
    isLoadingSignup,
  ]);

  const providerState: RedirectContextState = {
    handleRedirect: () => {},
  };

  return <RedirectContext.Provider value={providerState}>{children}</RedirectContext.Provider>;
};

export function useRedirectContext() {
  return useContext(RedirectContext);
}
