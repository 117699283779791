import { coreProgramRoute } from '@/modules/programs/domain/routes/core/programsRoutes';
import { Program } from '@/types';
import { requestQuery } from '@/services/request';
import { ProgramEntity } from '@/modules/programs/domain/entities/ProgramEntity';

export const getPrograms = async (programUUIDs: string[]) => {
  try {
    const data = await requestQuery<Program[]>({
      method: 'POST',
      url: coreProgramRoute,
      data: { UUIDs: programUUIDs },
    });

    return (data || []).map((d) => new ProgramEntity(d));
  } catch (e) {
    return [];
  }
};
