import classNames from 'classnames';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';

import { useWindowSize } from '@/hooks/useWindowSize';
import { Poster } from '@/modules/player/presentation/atoms/Poster';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';

interface MediaInfoProps {
  isPlayerMobile?: boolean;
}

export const MediaInfoWrapper = ({ isPlayerMobile = false }: MediaInfoProps) => {
  const { currentMedia, currentProgram } = usePlayer();
  const router = useRouter();
  const [animated, setAnimated] = useState({ title: false, subTitle: false });
  const { screenWidth } = useWindowSize();

  const marqueeTitle = useRef<HTMLLabelElement>(null);
  const marqueeSubTitle = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    setAnimated({ title: false, subTitle: false });
  }, [currentMedia?.UUID]);

  useEffect(() => {
    if (!currentMedia?.UUID || !marqueeTitle.current || !marqueeSubTitle.current) {
      setAnimated({ title: false, subTitle: false });
      return;
    }

    setTimeout(() => {
      const isTitleOverflowing =
        marqueeTitle.current?.scrollWidth > marqueeTitle.current?.clientWidth;
      const isSubTitleOverflowing =
        marqueeSubTitle.current?.scrollWidth > marqueeSubTitle.current?.clientWidth;
      setAnimated({ title: isTitleOverflowing, subTitle: isSubTitleOverflowing });
    }, 500);
  }, [currentMedia?.UUID, screenWidth, marqueeTitle, marqueeSubTitle]);

  const handleClickMediaTitle = useCallback(() => {
    if (!currentProgram?.route) return;

    router.push(currentProgram.route);
    document.getElementById('header')?.scrollIntoView();
  }, [currentProgram?.route, router]);

  return (
    <div
      className={classNames(
        'player-infos',
        {
          'grid-cols-[auto_1fr]': !isPlayerMobile,
        },
        { 'justify-items-center': isPlayerMobile }
      )}
    >
      <>
        <div>
          {currentMedia && (
            <Poster
              src={currentMedia.cover || currentProgram?.cover}
              alt={currentMedia.title}
              className={classNames({
                '!h-[200px]': isPlayerMobile,
              })}
            />
          )}
        </div>
        <div
          className={classNames('flex relative overflow-hidden', {
            'justify-center w-[70%]': isPlayerMobile,
          })}
        >
          <div className="w-full flex flex-col justify-center overflow-hidden text-sm">
            {animated.title ? (
              <Marquee gradient gradientColor="#12111c" gradientWidth={20} speed={15} delay={2}>
                <label
                  ref={marqueeTitle}
                  onClick={handleClickMediaTitle}
                  className="font-bold whitespace-nowrap text-white cursor-pointer hover:underline pe-5"
                >
                  {currentMedia?.title}
                </label>
              </Marquee>
            ) : (
              <label
                ref={marqueeTitle}
                onClick={handleClickMediaTitle}
                className="font-bold whitespace-nowrap text-white cursor-pointer hover:underline pe-5"
              >
                {currentMedia?.title}
              </label>
            )}

            {animated.subTitle ? (
              <Marquee gradient gradientColor="#12111c" gradientWidth={20} speed={15} delay={2}>
                <label
                  ref={marqueeSubTitle}
                  className="text-brand-neutral-300 text-start whitespace-nowrap leading-6 max-h-6 line-clamp-1 pe-5"
                >
                  {currentProgram?.title}
                </label>
              </Marquee>
            ) : (
              <label
                ref={marqueeSubTitle}
                className="text-brand-neutral-300 text-start whitespace-nowrap leading-6 max-h-6 line-clamp-1 pe-5"
              >
                {currentProgram?.title}
              </label>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export const MediaInfo = memo(MediaInfoWrapper);
