export enum NotificationTypes {
  Error = 'error',
  Information = 'information',
}

export type Notification = {
  title?: string;
  message?: string;
  titleParams?: Record<string, string>;
  messageParams?: Record<string, string>;
  type?: NotificationTypes;
};
