import { MediaEntity } from '@/modules/medias/domain/entities/MediaEntity';
import { coreMediasRoute } from '@/modules/medias/domain/routes/mediaRoutes';
import { requestQuery } from '@/services/request';
import { Media } from '@/types';

export const getMedias = async (UUIDs: string[]) => {
  const mediaUUIDs = UUIDs.filter(Boolean);
  try {
    const response = await requestQuery<Media[]>({
      method: 'POST',
      url: coreMediasRoute,
      data: { UUIDs: mediaUUIDs },
    });

    return (response || []).map((d) => new MediaEntity(d));
  } catch (e) {
    return [];
  }
};
