import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { playerRef, usePlayer, usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { useTrackPlayer } from '@/modules/analytic/hooks/useTrackPlayer';
import { useCallback } from 'react';
import { useFirebaseContext } from '@/providers/FirebaseAuthProvider';
import { MediaHistory } from '@/types';

export const usePlayerStart = () => {
  const { currentMedia, playbackURL, lastTimeInMedia, snooze } = usePlayer();
  const dispatch = usePlayerDispatch();
  const { trackPlayerPlay, trackStreamPlay } = useTrackPlayer();
  const { saveMediaHistory } = useFirebaseContext();

  const handleStart = useCallback(() => {
    if (!playbackURL) {
      return;
    }

    playerRef.current.seekTo(lastTimeInMedia || 0);

    if (!snooze) {
      dispatch({
        type: PlayerReducerActions.HANDLE_START,
      });
    }

    if (currentMedia) {
      saveMediaHistory(currentMedia as unknown as MediaHistory);

      trackPlayerPlay();
      trackStreamPlay(lastTimeInMedia);
    }
  }, [
    currentMedia,
    dispatch,
    lastTimeInMedia,
    playbackURL,
    saveMediaHistory,
    snooze,
    trackPlayerPlay,
    trackStreamPlay,
  ]);

  return handleStart;
};
