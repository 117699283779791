import { useQuery, useQueryClient } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useEffect } from 'react';

import { getMediasProgram } from '@/modules/medias/domain/resources';
import { getProgram } from '@/modules/programs/domain/resources';
import { getRoles } from '@/modules/user/domain/resources/getRoles';
import { Role } from '@/types';

export const useProgram = (programUUID: string, isPro?: boolean) => {
  const queryClient = useQueryClient();

  const { data, isFetching, error } = useQuery(
    ['program', programUUID],
    () => getProgram(programUUID, isPro),
    {
      enabled: !!programUUID,
      retry: 0,
    }
  );

  const { data: medias } = useQuery(
    ['program', data?.mediaKeyId, 'medias'],
    () => getMediasProgram(data.mediaKeyId),
    {
      enabled: !!data?.mediaKeyId,
      staleTime: Infinity,
    }
  );

  const { data: roles }: { data?: Role[] } = useQuery(
    ['roles', programUUID],
    () => getRoles(programUUID),
    {
      enabled: !!programUUID,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    queryClient.setQueryData(['current-medias'], medias || []);
  }, [medias, queryClient]);

  return {
    program: data,
    isLoadingProgram: isFetching,
    roles,
    medias,
    programError: error as HTTPError,
  };
};
