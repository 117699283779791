import { ConsentManager } from '@segment/consent-manager';
import Script from 'next/script';
import { useIntl } from 'react-intl';

import { config } from '@/utils';

export const BannerConsent = () => {
  const { formatMessage } = useIntl();

  const bannerContent = (
    <div className="text-brand-neutral-800 py-2">
      {formatMessage({ id: 'consentManager.bannerContent' })}
    </div>
  );

  const bannerActionsBlock = ({ acceptAll, denyAll }) => {
    return (
      <div className="flex justify-center items-center md:block">
        <button
          type="button"
          className="text-sm rounded-full flex justify-center items-center cursor-pointer my-4 md:mt-4 md:mb-2 mx-2 py-1 text-white text-bold bg-primary hover:bg-opacity-80 transition-colors w-36"
          onClick={acceptAll}
          aria-label={formatMessage({ id: 'consentManager.accept' })}
        >
          {formatMessage({ id: 'consentManager.accept' })}
        </button>
        <button
          type="button"
          className="text-sm flex justify-center items-center cursor-pointer mx-2 my-4 py-1 text-bold w-36"
          onClick={denyAll}
        >
          {formatMessage({ id: 'consentManager.reject' })}
        </button>
      </div>
    );
  };

  const preferencesDialogTemplate = {
    headings: {
      allowValue: 'Autoriser',
      categoryValue: 'Catégorie',
      purposeValue: 'Intérêt',
      toolsValue: 'Outils',
    },
    checkboxes: {
      noValue: 'Non',
      yesValue: 'Oui',
    },
    actionButtons: {
      cancelValue: 'Quitter',
      saveValue: 'Sauvegarder',
    },
    cancelDialogButtons: {
      cancelValue: 'Oui, quitter',
      backValue: 'Revenir',
    },
    categories: [
      {
        key: 'functional',
        name: 'Fonctionnel',
        description:
          'Pour contrôler les performances de notre site et améliorer votre expérience de navigation.',
        example:
          "Par exemple, ces outils vous permettent de communiquer avec nous par le biais d'un chat en direct.",
      },
      {
        key: 'marketing',
        name: 'Marketing et Analytics',
        description:
          'Pour comprendre le comportement des utilisateurs afin de vous offrir une expérience de navigation plus pertinente ou de personnaliser le contenu de notre site.',
        example:
          'Par exemple, nous recueillons des informations sur les pages que vous visitez pour nous aider à présenter des informations plus pertinentes.',
      },
      {
        key: 'advertising',
        name: 'Publicité',
        description:
          "Pour personnaliser et mesurer l'efficacité de la publicité sur notre site et d'autres sites web.",
        example:
          'Par exemple, nous pouvons vous proposer une publicité personnalisée en fonction des pages que vous visitez sur notre site.',
      },
      {
        key: 'essential',
        name: 'Essentiel',
        description:
          'Nous utilisons des cookies de navigateur qui sont nécessaires pour que le site fonctionne comme prévu.',
        example:
          'Par exemple, nous enregistrons vos préférences en matière de collecte de données sur le site Web afin de pouvoir les respecter si vous revenez sur notre site. Vous pouvez désactiver ces cookies dans les paramètres de votre navigateur, mais si vous le faites, le site risque de ne pas fonctionner comme prévu.',
      },
    ],
  };

  return (
    <div className="fixed bottom-0 z-[80] xl:w-1/3 w-2/3 md:w-1/2 rounded-md overflow-hidden mb-2 ms-2">
      <Script
        id="show-banner"
        dangerouslySetInnerHTML={{
          __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};;analytics.SNIPPET_VERSION="4.13.2";
          analytics.page();
          }}();`,
        }}
      />
      <ConsentManager
        writeKey={config.segmentApiKey}
        bannerContent={bannerContent}
        bannerSubContent={formatMessage({ id: 'consentManager.bannerSubContent' })}
        preferencesDialogTitle={formatMessage({ id: 'consentManager.preferencesDialogTitle' })}
        preferencesDialogContent={formatMessage({ id: 'consentManager.preferencesDialogContent' })}
        cancelDialogTitle={formatMessage({ id: 'consentManager.cancelDialogTitle' })}
        cancelDialogContent={formatMessage({ id: 'consentManager.cancelDialogContent' })}
        preferencesDialogTemplate={preferencesDialogTemplate}
        bannerActionsBlock={bannerActionsBlock}
        bannerBackgroundColor={'white'}
        bannerTextColor={'#1F202B'}
        defaultDestinationBehavior={'imply'}
      />
    </div>
  );
};
