import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@/components/Icon';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { usePlayerPlaying } from '@/modules/player/providers/dispatch/usePlayerPlaying';
import { usePlayerStartMedia } from '@/modules/player/providers/dispatch/usePlayerStartMedia';

export interface PlayPauseProps {
  className?: string;
}

export const PlayPause = ({ className = '' }: PlayPauseProps) => {
  const { formatMessage } = useIntl();
  const { handlePlayPause } = usePlayerPlaying();
  const { startMedia } = usePlayerStartMedia();
  const { playing, currentMedia } = usePlayer();
  const queryClient = useQueryClient();

  const mediasInCurrentPage = queryClient.getQueryData(['current-medias']);

  const handleClick = useCallback(() => {
    if (currentMedia?.UUID) {
      handlePlayPause();
      return;
    }
    if (!mediasInCurrentPage?.[0]) return;

    startMedia({ media: mediasInCurrentPage?.[0], autoPlay: true });
  }, [currentMedia?.UUID, mediasInCurrentPage, startMedia, handlePlayPause]);

  return (
    <button
      className={`play-button ${className}`}
      onClick={handleClick}
      type="button"
      aria-label={formatMessage({
        id: `player.accessibility.${playing ? 'pause' : 'play'}`,
      })}
    >
      <Icon
        name={playing ? 'pause' : 'play'}
        className={classNames({
          'text-brand-neutral-300': !currentMedia,
        })}
      />
    </button>
  );
};
