import { useEffect, useState } from 'react';
import { QueryObserver, useQueryClient } from '@tanstack/react-query';
import { retrieveAuthSession } from '@/utils/browserStorage';

export const useRefreshFirebaseTokens = () => {
  const queryClient = useQueryClient();
  const [enableSubscribe, setEnableSubscribe] = useState(false);
  const [auth, setAuth] = useState(retrieveAuthSession());

  useEffect(() => {
    setAuth(retrieveAuthSession());
  }, []);

  useEffect(() => {
    queryClient.getQueryCache().subscribe((query) => {
      if (query?.query?.options.queryKey === 'refreshTokens') {
        setEnableSubscribe(true);
      }
    });

    if (enableSubscribe) {
      const observer = new QueryObserver(queryClient, {
        queryKey: ['refreshTokens'],
      });

      const unsubscribe = observer.subscribe((result) => {
        if (result.status === 'success') {
          const authInSession = retrieveAuthSession();
          setAuth(authInSession);
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [enableSubscribe]);

  return auth;
};
