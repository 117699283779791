import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { sendBatchEvent } from '@/modules/analytic/providers/AnalyticProvider';
import { postSignup } from '@/modules/auth/domain/resources/core/postSignup';
import { useNotifications } from '@/modules/notifications/hooks/useNotifications';
import { LoginRequest } from '@/types';
import { HTTPApiError } from '@/utils/HTTPApiError';
import { setUserSession } from '@/utils/browserStorage';
import { authEmailRoute, homeRoute } from '@/utils/routes';

import { useLogin } from './useLogin';

export const useSignup = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showNotificationError } = useNotifications();
  const { login } = useLogin();

  const { mutateAsync: signup } = useMutation((data: LoginRequest) => postSignup(data), {
    onSuccess: (data, variables) => {
      setLoading(false);
      if (!data) {
        showNotificationError({
          title: 'common.notifications.error.signup.title',
          message: 'common.notifications.error.signup.description',
        });

        return;
      }

      if (variables.accountType === 'link') {
        router.push(authEmailRoute(variables.accountDatas.email));
      } else {
        queryClient.setQueryData(['user'], data);
        setUserSession(data);
        sendBatchEvent(data.UUID, 'signup_signup_app', {
          method: data.signUpType,
          event_sender: 'webapp',
        });

        router.push(homeRoute);
      }
    },
    onError: async (err: HTTPApiError, variables) => {
      setLoading(false);
      const errorMessage = await err.response.json();
      if (err?.response?.status === 409) {
        setStatus(409);
        login(variables);
        setNotification(errorMessage.error);
        return;
      }
      setError(errorMessage.error);
    },
  });

  return {
    signup,
    signupError: error,
    signupNotification: notification,
    signupStatus: status,
    isLoadingSignup: loading,
  };
};
