import { isAuthenticatedAsCorporate } from '@/modules/auth/utils/isAuthenticatedAsCorporate';
import { getMediasProgram } from '@/modules/medias/domain/resources/corpo/getMediasProgram';
import { DomainTypes } from '@/types';
import { getSourceFromMediaKeyId, getUUIDFromMediaKeyId } from '@/utils';

import { getQueueList as getQueueListCore } from './core/getQueueList';

export const getQueueList = async (
  programMediaKeyId: string,
  episode: number,
  remainingEpisodes?: number
) => {
  const source = getSourceFromMediaKeyId(programMediaKeyId);
  const programUUID = getUUIDFromMediaKeyId(programMediaKeyId);

  if (source === DomainTypes.Corporate && isAuthenticatedAsCorporate()) {
    return await getMediasProgram(programUUID);
  }

  return await getQueueListCore(programUUID, episode, remainingEpisodes);
};
