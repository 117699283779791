import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { useCallback } from 'react';

export const usePlayerSeekMouseDown = () => {
  const dispatch = usePlayerDispatch();

  const handleSeekMouseDown = useCallback(() => {
    dispatch({ type: PlayerReducerActions.HANDLE_SEEK_MOUSE_DOWN });
  }, [dispatch]);

  return handleSeekMouseDown;
};
