import { QueryCache, QueryClient } from '@tanstack/react-query';

export const isLocal = process.env.NEXT_PUBLIC_ENV === 'development';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: isLocal ? false : 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      staleTime: 1000 * 60 * 15,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta?.showNotificationError) {
        (query.meta?.showNotificationError as () => void)();
      }
    },
  }),
});
