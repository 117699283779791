import { useEffect, useMemo, useState } from 'react';

import { useAudiosByUUIDS } from '@/modules/audio/hooks/useAudiosByUUIDS';
import { useMedias } from '@/modules/medias/hooks/useMedias';
import { EmptyProgram } from '@/modules/programs/domain/entities/EmptyProgram';
import { usePrograms } from '@/modules/programs/hooks/usePrograms';
import { Media, MediaHistory, MediaInProgress } from '@/types';

export const useMediasPrograms = (mediasHistory: MediaHistory[] | Media[]) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mediasProgramsData, setMediasProgramsData] = useState<MediaInProgress[]>([]);

  const programsUUIDs = useMemo(
    () => mediasHistory.map(({ programUUID }: MediaHistory | Media) => programUUID),
    [mediasHistory]
  );
  const mediasUUIDs = useMemo(
    () => mediasHistory.map(({ UUID }: MediaHistory | Media) => UUID),
    [mediasHistory]
  );

  const { programs, isLoadingPrograms } = usePrograms(programsUUIDs || []);
  const { medias, isLoadingMedias } = useMedias(mediasUUIDs || []);
  const { audios, isLoadingAudios } = useAudiosByUUIDS(mediasUUIDs || []);

  useEffect(() => {
    if (isLoadingPrograms || isLoadingMedias || isLoadingAudios) return;

    if (!mediasHistory?.length || (!medias?.length && !audios?.length)) {
      setIsLoading(false);
      return;
    }

    const mediasProgramsHistory = mediasHistory
      .map(
        (mediaHistory: MediaHistory | Media) =>
          ({
            program:
              programs.find(({ UUID }) => UUID === mediaHistory.programUUID) || new EmptyProgram(),
            media:
              (medias || []).find(({ UUID }) => UUID === mediaHistory.UUID) ||
              (audios || []).find(({ mediaUUID }) => mediaUUID === mediaHistory.UUID)?.media,
            history: mediaHistory,
          } as MediaInProgress)
      )
      /** @todo: cleanup firestore for media / program that don't exist anymore */
      .filter(({ media }) => !!media);

    setMediasProgramsData(mediasProgramsHistory);
    setIsLoading(false);
  }, [
    isLoadingMedias,
    isLoadingPrograms,
    isLoadingAudios,
    medias,
    audios,
    programs,
    mediasHistory,
  ]);

  return { isLoading, mediasProgramsData };
};
