import Image from 'next/image';
import UAParser from 'ua-parser-js';

import { getUrlExtension } from '@/utils';

const UAString = new UAParser();

const isSafari = UAString.getBrowser()?.name?.includes('Safari') || false;

export interface PosterProps {
  src?: string;
  alt: string;
  className?: string;
}

export const Poster = ({ src = '/audio.svg', alt, className = '' }: PosterProps) => {
  const imageFormat = src ? getUrlExtension(src) : '';

  return (
    <div className={`img-square h-[90px] ${className}`}>
      {isSafari ? (
        <Image
          src={`${src}?q=10&w=90`}
          data-src={`${src}?auto=format&fm=${imageFormat}`}
          data-srcset={[
            16, 32, 48, 64, 96, 128, 256, 384, 640, 750, 828, 1080, 1200, 1920, 2048, 3840,
          ]
            .map((w) => `${src}?w=${w}&auto=format&fm=${imageFormat}`)
            .join(',')}
          fill
          loading="lazy"
          alt={alt}
        />
      ) : (
        <Image src={src} fill priority alt={alt} />
      )}
    </div>
  );
};
