import { useCallback, useRef } from 'react';

import { useNotifications } from '@/modules/notifications/hooks/useNotifications';
import { usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';

export const usePlayerError = () => {
  const dispatch = usePlayerDispatch();
  const { showNotificationError } = useNotifications();

  const errorRetry = useRef(0);

  const handleError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, data: any) => {
      if (data?.response?.code >= 400 && data?.fatal && errorRetry?.current < 1) {
        errorRetry.current = 1;

        /* startMedia({
        media: state.currentMedia,
        lastTimeInMedia: state.alreadyPlayed,
        programTitle: state.currentProgram?.title,
        autoPlay: true,
      }); */

        return;
      }

      if ((data && errorRetry?.current >= 1) || data >= 400) {
        dispatch({ type: PlayerReducerActions.HANDLE_STOP });
        showNotificationError('startMedia.notification.error');
        errorRetry.current = 0;
      }
    },
    [errorRetry, dispatch, showNotificationError]
  );

  return handleError;
};
