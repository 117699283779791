import { CorporateMediaEntity } from '@/modules/medias/domain/entities/CorporateMediaEntity';
import { corporateProgramByIdRoute } from '@/modules/programs/domain/routes/core/programsRoutes';
import { CorporateProgram } from '@/types';
import { getData } from '@/utils/corpo/request/getData';

export const getMediasProgram = async (programUUID: string) => {
  if (!programUUID) throw new Error('no programUUID provided');

  const response = await getData<CorporateProgram>(corporateProgramByIdRoute(programUUID));

  return (response?.medias || []).map((d) => new CorporateMediaEntity(d).toMedia());
};
