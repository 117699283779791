import { isUndefined } from 'lodash';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { useAnalyticContext } from '@/modules/analytic/providers/AnalyticProvider';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { ANALYTICS_KEYS, Media, QueuePosition } from '@/types';

interface TrackAddToQueueProps {
  inQueue: Media[];
  added?: Media[];
  direction?: QueuePosition;
  isEnd?: boolean;
}

interface TrackRemoveFromQueueProps {
  inQueue: Media[];
  removed?: Media[];
}

export const useTrackQueueList = () => {
  const { queueList } = usePlayer();
  const { track } = useAnalyticContext();
  const { asPath } = useRouter();

  const queueListMediaUUIDS = useMemo(() => queueList.map(({ UUID }) => UUID), [queueList]);

  const trackSeeQueue = useCallback(() => {
    track(ANALYTICS_KEYS.QUEUE_SEE_QUEUE, {
      mediaUUIDs_in_queue: queueListMediaUUIDS,
      source: asPath,
    });
  }, [asPath, queueListMediaUUIDS, track]);

  const trackAddToQueue = useCallback(
    ({ inQueue, added, direction, isEnd }: TrackAddToQueueProps) => {
      track(ANALYTICS_KEYS.QUEUE_ADD_MEDIAS, {
        mediaUUIDs_in_queue: inQueue.map(({ UUID }) => UUID),
        mediaUUIDs_added_queue: added.map(({ UUID }) => UUID),
        end_of_queue: !isUndefined(isEnd) ? isEnd : direction === 'bottom' ? 'true' : 'false',
      });
    },
    [track]
  );

  const trackRemoveFromQueue = useCallback(
    ({ inQueue, removed }: TrackRemoveFromQueueProps) => {
      track(ANALYTICS_KEYS.QUEUE_REMOVE_MEDIAS, {
        mediaUUIDs_in_queue: inQueue.map(({ UUID }) => UUID),
        mediaUUIDs_removed_queue: removed.map(({ UUID }) => UUID),
      });
    },
    [track]
  );

  const trackReorderQueue = useCallback(() => {
    track(ANALYTICS_KEYS.QUEUE_REORDER_QUEUE, {
      mediaUUIDs_in_queue: queueListMediaUUIDS,
    });
  }, [queueListMediaUUIDS, track]);

  return { trackSeeQueue, trackAddToQueue, trackRemoveFromQueue, trackReorderQueue };
};
