import { createContext } from 'react';

import { User } from '@/types';

export type AuthContextState = {
  error: boolean;
  user: User | null;
  isLoadingUser: boolean;
};

export const AuthContext = createContext<AuthContextState>(null);
