import { CorporateProgramEntity } from '@/modules/programs/domain/entities/CorporateProgramEntity';
import { corporateProgramsRoute } from '@/modules/programs/domain/routes/core/programsRoutes';
import { CorporateProgram } from '@/types';
import { getData } from '@/utils/corpo/request/getData';

export const getPrograms = async () => {
  try {
    const response = await getData<{ data: CorporateProgram[] }>(corporateProgramsRoute);

    return (response?.data || []).map((d) => new CorporateProgramEntity(d).toProgram());
  } catch (e) {
    return [];
  }
};
