import ky, { HTTPError } from 'ky';

import { logout as logoutCorporate } from '@/modules/auth/domain/resources/corporate/logout';
import { getRefreshToken } from '@/services/request/getRefreshToken';
import { shouldRefreshToken } from '@/services/request/shouldRefreshToken';
import { Query } from '@/types';
import { config } from '@/utils';
import { deleteAllSessions, retrieveAuthSession, setAuthSession } from '@/utils/browserStorage';

export const requestQuery: <T>(query: Query, prefix?: string) => Promise<T> = async (query) => {
  const searchParams = new URLSearchParams();
  Object.entries(query?.params || {}).map(([key, value]) => {
    searchParams.append(key, value);
  });

  const headers = {
    Accept: 'application/com.majelan.v3',
    ...(!query.body && {
      'Content-Type': 'application/json',
    }),
    'majelan-app-version': `webapp/${config.version}`,
    'Content-Negotiation': 'v2',
    ...(query.headers || {}),
  };

  const response = await ky(query.url, {
    prefixUrl: config.majelanApi,
    headers,
    credentials: 'include',
    method: query.method,
    ...(query.data && {
      json: { ...query.data },
    }),
    body: query.body || null,
    ...(searchParams.size && {
      searchParams,
    }),
    timeout: 60000,
    hooks: {
      beforeRequest: [
        async (request) => {
          if (query.noAuth) return;

          const session = retrieveAuthSession();
          request.headers.set('Authorization', `Bearer ${session?.accessToken}`);

          const refresh = shouldRefreshToken();
          if (refresh) {
            setAuthSession({ ...session, refreshing: true });
            const tokens = await getRefreshToken();
            if (!tokens) {
              console.error('tokens not found');
              deleteAllSessions();
              logoutCorporate('');
              return;
            }
            setAuthSession({ ...session, ...tokens, refreshing: false });
            request.headers.set('Authorization', `Bearer ${tokens?.accessToken}`);
          }
        },
      ],
      beforeError: [
        async (error) => {
          console.error('Error with request: ', error.request);
          if (error.response?.status === 401 && !query.noAuth) {
            const session = retrieveAuthSession();
            if (!session?.accessToken) {
              console.error('no valid session found');
              deleteAllSessions();
              return;
            }

            const tokens = await getRefreshToken();
            if (!tokens) {
              console.error('tokens not found');
              /* deleteAllSessions(); */
              return;
            }
            setAuthSession(tokens);
            return requestQuery(query);
          }

          if (error.response?.status === 500) {
            try {
              await error.response.json();
              return error;
            } catch (err) {
              return new HTTPError(
                error.response,
                {
                  ...error.request,
                  json: async () => ({
                    error: 'unknown',
                  }),
                },
                error.options
              );
            }
          }

          return error;
        },
      ],
    },
  });

  /**
   * This handle cases where API sends back response with a status different than 204
   * and an empty response
   * which would cause: SyntaxError: JSON.parse: unexpected end of data at line 1 column 1 of the JSON data
   */
  try {
    const json = await response.json();
    return json;
  } catch (err) {
    console.error(`'${err}' happened, but no big deal!`);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Promise.resolve({} as any);
  }
};
