import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { Media } from '@/types';
import { useCallback } from 'react';

export const usePlayerReorderQueueList = () => {
  const dispatch = usePlayerDispatch();

  const reorderQueueList = useCallback(
    (reorderedQueueList: Media[]) => {
      dispatch({
        type: PlayerReducerActions.SET_QUEUE_LIST,
        payload: { queueList: reorderedQueueList },
      });
    },
    [dispatch]
  );

  return reorderQueueList;
};
