import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import classNames from 'classnames';
import { memo, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { useTrackQueueList } from '@/modules/analytic/hooks/useTrackQueueList';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { usePlayerQueueListDisplay } from '@/modules/player/providers/dispatch/usePlayerQueueListDisplay';
import { usePlayerReorderQueueList } from '@/modules/player/providers/dispatch/usePlayerReorderQueueList';

import { QueueListMedia } from './QueueListMedia';

const QueueListWrapper = () => {
  const { formatMessage } = useIntl();
  const { queueList } = usePlayer();
  const [playlistScrollPosition, setPlaylistScrollPosition] = useState<
    'top' | 'top-bottom' | 'bottom'
  >('top');
  const [reorganizeQueueList, setReorganizeQueueList] = useState(false);
  const playlistDivRef = useRef<HTMLDivElement>(null);
  const reorderQueueList = usePlayerReorderQueueList();
  const handleQueueListDisplay = usePlayerQueueListDisplay();
  const { trackReorderQueue /* trackSeeQueue */ } = useTrackQueueList();

  /* useEffect(trackSeeQueue, [trackSeeQueue]); */

  /* const throttleAnalytics = useCallback(
    () =>
      throttle(trackSeeQueue, 50000, {
        leading: true,
        trailing: true,
      }),
    [trackSeeQueue]
  ); */

  const handleScroll = () => {
    if (!playlistDivRef?.current) return;

    /* throttleAnalytics(); */

    const playlistDiv = playlistDivRef?.current;
    const playlistDivScrollTop = playlistDiv!.scrollTop;

    if (playlistDivScrollTop <= 0) return setPlaylistScrollPosition('top');

    if (playlistDivScrollTop > 0) return setPlaylistScrollPosition('top-bottom');

    const scrollableHeight = playlistDiv!.scrollHeight - playlistDiv!.clientHeight;

    if (playlistDivScrollTop >= scrollableHeight) return setPlaylistScrollPosition('bottom');
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = queueList.findIndex((item) => item.UUID === active.id);
    const newIndex = queueList.findIndex((item) => item.UUID === over.id);

    const sortedList = arrayMove(queueList, oldIndex, newIndex);
    reorderQueueList(sortedList);
  };

  const handleReorganizeQueueList = () => {
    if (reorganizeQueueList) {
      trackReorderQueue();
    }

    setReorganizeQueueList(!reorganizeQueueList);
  };

  const handleCloseQueueList = () => {
    handleQueueListDisplay(false);
  };

  const mediaUUIDs = useMemo(() => queueList.map((a) => a.UUID), [queueList]);

  return (
    <div className="flex flex-col gap-2 pt-4">
      <div className="flex flex-row justify-between md:items-center items-stretch">
        <div className="px-5">
          <p className="font-semibold text-lg">
            {formatMessage({ id: 'player.queueList.details' })}
          </p>
          <span className="text-sm font-light text-brand-neutral-300">
            {formatMessage(
              { id: 'player.queueList.length' },
              {
                length: queueList.length,
              }
            )}
          </span>
        </div>

        <div className="flex px-5">
          {!!queueList.length && (
            <Button
              onClick={handleReorganizeQueueList}
              ariaLabel={formatMessage({
                id: reorganizeQueueList
                  ? 'player.queuelist.reorganize.end'
                  : 'player.queuelist.reorganize',
              })}
              iconOnly
              style="link"
            >
              <Icon name={reorganizeQueueList ? 'check' : 'arrows-down-up'} className="text-2xl" />
            </Button>
          )}
          <Button
            onClick={handleCloseQueueList}
            ariaLabel={formatMessage({ id: 'player.accessibility.queueList.close' })}
            iconOnly
            style="link"
          >
            <Icon name="close-off" className="text-2xl" />
          </Button>
        </div>
      </div>

      {!queueList?.length ? (
        <div className="md:h-[calc(100vh-200px)] flex flex-col items-center justify-center text-center">
          <p className="mb-4">{formatMessage({ id: 'player.queueList.emptyView.title' })}</p>

          <p className="text-sm text-brand-neutral-300">
            {formatMessage({ id: 'player.queueList.emptyView.description' })}
          </p>
        </div>
      ) : (
        <div
          className={classNames(
            'relative scrollable-hidden md:h-[calc(100vh-200px)]',
            {
              'scrollable-bottom-shadow ': playlistScrollPosition === 'top',
            },
            {
              'scrollable-bottom-shadow scrollable-top-shadow':
                playlistScrollPosition === 'top-bottom',
            },
            {
              'scrollable-top-shadow': playlistScrollPosition === 'bottom',
            }
          )}
        >
          <div
            className="bg-brand-neutral-900 md:w-full h-full rounded-t-md overflow-y-auto relative scrollable-playlist mx-1"
            onScroll={handleScroll}
            ref={playlistDivRef}
          >
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext items={mediaUUIDs} strategy={verticalListSortingStrategy}>
                <ul className="md:max-h-[calc(100vh-200px)] relative flex flex-col">
                  {queueList.map((media) => (
                    <QueueListMedia
                      key={media.UUID}
                      media={media}
                      reorganizeQueueList={reorganizeQueueList}
                    />
                  ))}
                </ul>
              </SortableContext>
            </DndContext>
          </div>
        </div>
      )}
    </div>
  );
};

export const QueueList = memo(QueueListWrapper);
