import { baseUrl } from 'tests/helpers';

import { DomainTypes, MediaInitializer, MediaStatus } from '@/types';
import { mediaRoute, proMediaRoute, shareMediaRoute, shareProMediaRoute } from '@/utils/routes';

export class MediaEntity {
  mediaKeyId: string;
  type: 'media' | 'audio';
  source: DomainTypes;
  domain: string;
  UUID: string;
  audioUUID: string;
  description: string;
  verticalPictureURL: string;
  cover: string;
  duration?: number;
  enabled: boolean;
  endPublicationDate: number;
  episode?: number;
  lang: string;
  programMediaKeyId?: string;
  programUUID?: string;
  playbackURL: string;
  publicationDate: number;
  status: MediaStatus;
  summary: string;
  syllabus: string[];
  title: string;
  transcriptURL?: string;
  uploadURL: string;
  keyword1?: string;
  isFree?: boolean;
  trailer?: boolean;
  creationDate: number;
  modificationDate: number;
  deleted: boolean;
  isCorporate: boolean;
  isAvailable: boolean;
  isNew: boolean;
  isComingSoon: boolean;
  route: string;
  shareRoute: string;

  constructor(media: MediaInitializer) {
    this.mediaKeyId = `A::${media.source || DomainTypes.Internal}::${media.domain || 'majelan'}::${
      media.programUUID
    }::${media.UUID}`;
    this.type = media.type || 'media';
    this.source = media.source || DomainTypes.Internal;
    this.domain = media.domain || 'majelan';
    this.UUID = media.UUID;
    this.audioUUID = media.audioUUID;
    this.description = media.description || '';
    this.cover = media.verticalPictureURL;
    this.duration = media.duration;
    this.enabled = media.enabled;
    this.endPublicationDate = media.endPublicationDate;
    this.episode = media.episode || 0;
    this.lang = media.lang;
    this.programMediaKeyId = `A::${media.source || DomainTypes.Internal}::${
      media.domain || 'majelan'
    }::${media.programUUID}`;
    this.programUUID = media.programUUID;
    this.playbackURL = media.playbackURL;
    this.publicationDate = media.publicationDate;
    this.status = media.status;
    this.summary = media.summary;
    this.syllabus = media.syllabus;
    this.title = media.title;
    this.transcriptURL = media.transcriptURL;
    this.uploadURL = media.uploadURL;
    this.keyword1 = media.keyword1;
    this.isFree = media.isFree;
    this.trailer = media.trailer;
    this.programUUID = media.programUUID;
    this.creationDate = media.creationDate;
    this.modificationDate = media.modificationDate;
    this.deleted = media.deleted;

    this.isCorporate = media.source === DomainTypes.Corporate;
    this.isAvailable = media.status !== 'comingSoon';
    this.isComingSoon = media.status === 'comingSoon';
    this.isNew = media.status === 'new';

    this.route = this.isCorporate
      ? proMediaRoute(this.UUID, this.programUUID, this.domain)
      : mediaRoute(this.UUID, this.programUUID);

    this.shareRoute = `${baseUrl}${
      this.isCorporate
        ? shareProMediaRoute(this.UUID, this.programUUID, this.domain)
        : shareMediaRoute(this.UUID, this.programUUID)
    }`;
  }
}
