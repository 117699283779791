import { useCallback, useContext } from 'react';

import { useAuth } from '@/modules/auth/hooks/useAuth';
import { PlayerDispatchContext } from '@/modules/player/providers/PlayerDispatchContext';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import {
  PlayerReducerActions,
  StartMediaProps,
} from '@/modules/player/providers/PlayerProvider.types';
import { isMediaPlayable } from '@/modules/player/utils/isMediaPlayable';

import { usePlayerPlaying } from './usePlayerPlaying';

export const usePlayerStartMedia = () => {
  const { user } = useAuth();
  const { currentMedia } = usePlayer();
  const { handlePlay, handlePlayPause } = usePlayerPlaying();
  const dispatch = useContext(PlayerDispatchContext);

  const startMedia = useCallback(
    (mediaToPlay: StartMediaProps) => {
      if (!isMediaPlayable({ isUserPremium: user?.premium, media: mediaToPlay.media })) return;

      if (currentMedia?.UUID === mediaToPlay.media?.UUID) {
        handlePlayPause();
        return;
      }

      dispatch({
        type: PlayerReducerActions.SET_MEDIA_TO_PLAY,
        payload: {
          mediaToPlay,
        },
      });

      if (mediaToPlay.autoPlay) {
        setTimeout(() => handlePlay(mediaToPlay.media), 500);
      }
    },
    [user?.premium, currentMedia?.UUID, dispatch, handlePlay, handlePlayPause]
  );

  return { startMedia };
};
