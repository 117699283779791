import { ContentTypes } from './analytics';
import { CorporateProgramWithOrder, Program, ProgramInitializer } from './program';

export interface BlockInitializer {
  UUID?: string;
  contentType?: ContentTypes;
  /* "format" is the value sent by the Core API, we use it to define "size" in the app */
  format?: BlockFormats;
  items: ProgramInitializer[];
  populated?: boolean;
  randomOrder?: number;
  title: string;
  type?: string;
}

export interface Block extends BlockInitializer {
  items: Program[];
  size: keyof typeof BlockSizes;
}

export interface Selection {
  selection: {
    domain: string;
    uuid: string;
    name: string;
    programs: CorporateProgramWithOrder[];
    createdAt: number;
    updateAt: number;
  };
  order: number;
  displaySize: DisplaySizes;
}

export enum DisplaySizes {
  Header = 'header',
  Portrait = 'portrait',
  Landscape = 'landscape',
}

export enum BlockFormats {
  HeaderCarousel = 'headerCarousel',
  HighCarousel = 'highCarousel',
  LargeCarousel = 'largeCarousel',
  Large = 'large',
  Progress = 'progress',
  Ad = 'ad',
}

export enum BlockSizes {
  'header',
  'default',
  'progress',
  'ad',
}
