import { isAuthenticatedAsCorporate } from '@/modules/auth/utils/isAuthenticatedAsCorporate';
import { getProgram as getProgramCore } from '@/modules/programs/domain/resources/core/getProgram';
import { getPrograms as getProgramsCore } from '@/modules/programs/domain/resources/core/getPrograms';
import { getProgram as getProgramCorporate } from '@/modules/programs/domain/resources/corpo/getProgram';
import { getPrograms as getProgramsCorporate } from '@/modules/programs/domain/resources/corpo/getPrograms';
import { DomainTypes } from '@/types';
import { getSourceFromMediaKeyId, getUUIDFromMediaKeyId } from '@/utils';

export const getProgram = async (programMediaKeyId: string, isPro?: boolean) => {
  const source = getSourceFromMediaKeyId(programMediaKeyId);
  const programUUID = getUUIDFromMediaKeyId(programMediaKeyId);

  /* If there is no programUUID, it is probably an audio generated media */
  if (!programUUID) return;

  if ((isPro || source === DomainTypes.Corporate) && isAuthenticatedAsCorporate()) {
    return await getProgramCorporate(programUUID);
  }

  return await getProgramCore(programUUID);
};

export const getPrograms = async (programUUIDs = []) => {
  if (!programUUIDs.length) return;

  try {
    const corePrograms = await getProgramsCore(programUUIDs);
    const corporatePrograms = isAuthenticatedAsCorporate() ? await getProgramsCorporate() : [];

    /** @todo: So far, we do not get specific programs from Corporate API */
    return [
      ...corePrograms,
      ...(corporatePrograms || []).filter(({ UUID }) => programUUIDs.includes(UUID)),
    ];
  } catch (e) {
    return null;
  }
};
