import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig } from 'firebase/remote-config';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);

if (process.env.NODE_ENV === 'development') {
  const auth = getAuth(firebaseApp);

  const firebaseAuthPort = process.env.NEXT_PUBLIC_FIREBASE_AUTH_PORT || 9099;
  const firebaseFirestorePort = +(process.env.NEXT_PUBLIC_FIREBASE_FIRSTORE_PORT || 8088);

  connectAuthEmulator(auth, `http://127.0.0.1:${firebaseAuthPort}`);

  const db = getFirestore();
  connectFirestoreEmulator(db, '127.0.0.1', firebaseFirestorePort);
}

if (typeof window !== 'undefined') {
  getAnalytics(firebaseApp);
  const remoteConfig = getRemoteConfig(firebaseApp);
  remoteConfig.settings.minimumFetchIntervalMillis = 0;
}
