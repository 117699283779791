import { UserEntity } from '@/modules/user/domain/entities/UserEntity';
import { coreUserByIdRoute } from '@/modules/user/domain/routes/userRoutes';
import { requestQuery } from '@/services/request';
import { User } from '@/types';

export const getUser = async (userId: string) => {
  try {
    const response = await requestQuery<User>({
      url: coreUserByIdRoute(userId),
    });

    return !response ? null : new UserEntity(response);
  } catch (e) {
    return null;
  }
};
