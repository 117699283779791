import { DateTime } from 'luxon';
import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  doc,
  collection,
  where,
  query,
  getDoc,
  setDoc,
  getDocs,
} from 'firebase/firestore';

import { queryClient } from '@/utils/request';
import { firebaseApp } from '@/services/firebase';
import { FirestoreCollections } from '@/types/firestore';
import { MediaHistory, Program } from '@/types';

const firebaseAuth = getAuth();
const firebaseDb = getFirestore(firebaseApp);

export const saveProgramHistory = async (mediaHistory: MediaHistory) => {
  const userUUID = firebaseAuth.currentUser?.uid || '';

  if (!userUUID) return;

  const { programUUID, UUID } = mediaHistory;

  const programMedias: Program[] =
    queryClient.getQueryData(['program', programUUID, 'medias']) || [];

  let mediaHistories: MediaHistory[] = [];

  try {
    const queryPlayHistoryOfProgram = query(
      collection(
        firebaseDb,
        FirestoreCollections.Users,
        userUUID,
        FirestoreCollections.PlayHistory
      ),
      where('programUUID', '==', programUUID)
    );

    const querySnapshot = await getDocs(queryPlayHistoryOfProgram);
    querySnapshot.forEach((doc) => {
      mediaHistories = [...mediaHistories, doc.data() as MediaHistory];
    });
  } catch (error) {
    //
  }

  let oldProgramHistory = { isCompleted: false };
  try {
    const docRef = doc(
      firebaseDb,
      FirestoreCollections.Users,
      userUUID,
      FirestoreCollections.ProgramHistory,
      programUUID
    );

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      oldProgramHistory = docSnap.data() as MediaHistory;
    }
  } catch (error) {
    //
  }

  const areListenedMediasCompleted = [
    ...mediaHistories.filter(({ UUID: oldMediaUUID }) => oldMediaUUID !== UUID),
    mediaHistory,
  ].every(({ isCompleted }) => isCompleted);

  const isProgramComplete =
    areListenedMediasCompleted &&
    mediaHistories.length === programMedias.length &&
    !oldProgramHistory.isCompleted;

  if (!isProgramComplete) return;

  const programHistory = {
    isCompleted: true,
    lastCompletionDate: DateTime.now().toMillis(),
    userUUID,
    UUID: programUUID,
  };

  const programHistoryRef = doc(
    firebaseDb,
    FirestoreCollections.Users,
    userUUID,
    FirestoreCollections.ProgramHistory,
    programUUID
  );

  setDoc(programHistoryRef, programHistory, { merge: true });
};
