import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { config } from '@/utils';
import { isOnAuth } from '@/utils/routes';

export interface HeadProps {
  metas?: { title: string } | null;
}
export const Head = ({ metas: metasProps }: HeadProps) => {
  const { formatMessage } = useIntl();
  const { asPath, pathname } = useRouter();

  const metas = metasProps || {
    title: formatMessage({ id: 'metas.global.title' }),
  };

  const { title, description } = {
    title: `${process.env.NEXT_PUBLIC_APP_NAME} - ${metas?.title || ''}`,
    description:
      'Les meilleurs livres audio, documentaires et podcasts exclusifs pour découvrir chaque jour les plus grands auteurs. En illimité, sans publicité. Sur iOS, Android et web.',
  };

  const image = 'https://images.majelan.audio/divers/v2/MAJELAN_HOME.png?w=1200&h=630&fm=jpg&q=70';

  return (
    <NextHead>
      <link rel="canonical" href={`${config.majelanWebapp}${asPath}`} />
      <link rel="alternate" href={`android-app://${process.env.NEXT_PUBLIC_PLAYSTORE_ID}`} />
      <link rel="alternate" href={`ios-app://${process.env.NEXT_PUBLIC_APPSTORE_ID}`} />
      <meta property="al:android:app_name" content={process.env.NEXT_PUBLIC_APP_NAME} />
      <meta property="al:android:package" content={process.env.NEXT_PUBLIC_PLAYSTORE_ID} />
      <meta property="al:ios:app_name" content={process.env.NEXT_PUBLIC_APP_NAME} />
      <meta property="al:ios:app_store_id" content={process.env.NEXT_PUBLIC_APPSTORE_ID} />
      {/* Google / Search Engine Tags */}
      <meta property="google" content="notranslate" />
      {/* Facebook Meta Tags */}
      <meta property="og:url" content={`${config.majelanWebapp}${asPath}`} />
      <meta property="og:site_name" content={process.env.NEXT_PUBLIC_APP_NAME} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="fb:app_id" content={process.env.NEXT_PUBLIC_FACEBOOK_ID} />
      {/* Twitter Meta Tags */}
      <meta name="twitter:creator" content={process.env.NEXT_PUBLIC_MAJELAN_TWITTER_ACCOUNT} />
      <meta name="twitter:site" content={process.env.NEXT_PUBLIC_MAJELAN_TWITTER_ACCOUNT} />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="application-name" content={process.env.NEXT_PUBLIC_APP_NAME} />
      <meta name="apple-mobile-web-app-title" content={process.env.NEXT_PUBLIC_APP_NAME} />
      <meta name="theme-color" content="#343546" />
      <meta name="msapplication-navbutton-color" content="#343546" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      <meta name="msapplication-starturl" content={config.majelanWebapp} />
      <link
        rel="apple-touch-icon"
        type="image/png"
        sizes="100x100"
        href={`/${process.env.NEXT_PUBLIC_FAVICON}.png`}
      />
      <link rel="apple-touch-icon" type="image/png" sizes="200x200" href="/logo@2x.png" />
      <link rel="apple-touch-icon" type="image/png" sizes="300x300" href="/logo@3x.png" />
      <link
        rel="icon"
        type="image/png"
        sizes="100x100"
        href={`/${process.env.NEXT_PUBLIC_FAVICON}.png`}
      />
      <link rel="icon" type="image/png" sizes="200x200" href="/logo@2x.png" />
      <link rel="icon" type="image/png" sizes="300x300" href="/logo@3x.png" />
      <link rel="shortcut icon" href={`/${process.env.NEXT_PUBLIC_FAVICON}.ico`} />
      <link rel="manifest" href="/manifest.json" />
      {isOnAuth(pathname) && <link rel="preload" href="/login.png" as="image" />}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Google / Search Engine Tags */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />
      {/* Facebook Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={title} />
      {/* Twitter Meta Tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:type" content="website" />
    </NextHead>
  );
};
