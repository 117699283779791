import { ProgramEntity } from '@/modules/programs/domain/entities/ProgramEntity';
import { coreProgramByIdRoute } from '@/modules/programs/domain/routes/core/programsRoutes';
import { requestQuery } from '@/services/request';
import { Program } from '@/types';

export const getProgram = async (programUUID: string): Promise<Program> => {
  const response = await requestQuery<Program>({
    url: coreProgramByIdRoute(programUUID),
  });

  return response && new ProgramEntity(response);
};
