import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { useCallback } from 'react';

export const usePlayerSound = () => {
  const dispatch = usePlayerDispatch();

  const handleSound = useCallback(
    (sound: number) => {
      dispatch({ type: PlayerReducerActions.HANDLE_SOUND, payload: { sound } });
    },
    [dispatch]
  );

  return handleSound;
};
