import Image from 'next/image';
import { ReactNode, memo } from 'react';

import { Notifications } from '@/modules/notifications/presentation/Notifications';

interface LayoutAuthProps {
  children: ReactNode;
}
const LayoutAuth = ({ children }: LayoutAuthProps) => {
  return (
    <>
      <Notifications />
      <div className="min-h-full flex">
        <div className="min-h-screen w-1/2 max-md:w-full flex flex-col items-center bg-brand-neutral-900">
          <div className="flex justify-center">
            <Image
              src="/logo-majelan.png"
              alt="Majelan logo"
              className="h-14 w-auto mt-10"
              width={200}
              height={50}
            />
          </div>
          <div className="w-full flex-1 flex flex-col justify-center max-w-sm max-sm:px-5">
            {children}
          </div>
        </div>
        <div className="h-screen w-1/2 max-md:hidden flex justify-center auth-background">
          <div className="flex flex-col justify-end items-center mb-52">
            <Image src="/reassurance.png" alt="" width={300} height={200} />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(LayoutAuth);
