import { DomainTypes, Media, ProgramInitializer, ProgramStatus, Trailer, Typology } from '@/types';
import { proProgramRoute, programRoute } from '@/utils/routes';

export class ProgramEntity {
  mediaKeyId: string;
  source: DomainTypes;
  domain: string;
  UUID: string;
  description: string;
  endPublicationDate: number;
  publicationDate?: number;
  medias: Media[];
  modificationDate?: number;
  verticalPictureURL: string;
  programTypeKey: string;
  keyword1: string;
  lang: string;
  status: ProgramStatus;
  studio: string;
  syllabus: string[];
  title: string;
  trailer: Trailer;
  typology: Typology;
  cover: string;
  isFree?: boolean;
  teamUUID?: string;
  isCorporate: boolean;
  isAvailable: boolean;
  route: string;

  constructor(program: ProgramInitializer) {
    this.mediaKeyId = `A::${
      !program.source || program.source === DomainTypes.Majelan
        ? DomainTypes.Internal
        : program.source !== DomainTypes.Corporate
        ? DomainTypes.External
        : DomainTypes.Corporate
    }::${program.domain || DomainTypes.Majelan}::${program.UUID}`;
    this.source = (
      !program.source || program.source === DomainTypes.Majelan
        ? DomainTypes.Internal
        : program.source !== DomainTypes.Corporate
        ? DomainTypes.External
        : DomainTypes.Corporate
    ) as DomainTypes;
    this.domain = program.domain || DomainTypes.Majelan;
    this.UUID = program.UUID;
    this.cover = program.cover || program.verticalPictureURL || '/audio.svg';
    this.description = program.description;
    this.endPublicationDate = program.endPublicationDate;
    this.publicationDate = program.publicationDate;
    this.medias = program.medias || [];
    this.modificationDate = program.modificationDate;
    this.programTypeKey = program.programTypeKey;
    this.keyword1 = program.keyword1;
    this.lang = program.lang;
    this.status = program.status;
    this.studio = program.studio;
    this.syllabus = program.syllabus || [];
    this.title = program.title;
    this.trailer = program.trailer;
    this.typology = program.typology || Typology.Linear;
    this.isFree = program.isFree;
    this.teamUUID = program.teamUUID;
    this.isCorporate = this.source === DomainTypes.Corporate;
    this.isAvailable = this.status !== ProgramStatus.ComingSoon;
    this.route = this.isCorporate
      ? proProgramRoute(this.UUID, this.domain)
      : programRoute(this.UUID);
  }
}
