import { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@/components/Icon';
import { useTrackPlayer } from '@/modules/analytic/hooks/useTrackPlayer';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { usePlayerPlaying } from '@/modules/player/providers/dispatch/usePlayerPlaying';
import { usePlayerSnooze } from '@/modules/player/providers/dispatch/usePlayerSnooze';
import { getReadableTimeFromSec } from '@/utils';

const secondsInMinute = 60;
const snoozeLevels = [5, 10, 15, 30, 45, 60, 90];

const SnoozeWrapper = () => {
  const { currentMedia, playing, remainingTime } = usePlayer();
  const [openLevelsModal, setOpenLevelsModal] = useState(false);
  const [timerOn, setTimerOn] = useState(false);
  const [timer, setTimer] = useState(0);
  const [timerEndOfEpisode, setTimerEndOfEpisode] = useState(false);
  const { trackPlayerSnooze } = useTrackPlayer();
  const { handlePlayPause } = usePlayerPlaying();
  const handleSnooze = usePlayerSnooze();

  const { formatMessage } = useIntl();

  const handleTimer = (level: number, endOfEpisode = false) => {
    setTimer(level * secondsInMinute);
    setTimerEndOfEpisode(endOfEpisode);
    setTimerOn(true);
    setOpenLevelsModal(false);
    trackPlayerSnooze(endOfEpisode, level);
  };

  const handleStopTimer = () => {
    setTimerOn(false);
    setOpenLevelsModal(!openLevelsModal);
  };

  useEffect(() => {
    if (playing) {
      handleSnooze(false);
    }
  }, [playing, handleSnooze]);

  useEffect(() => {
    if ((timerOn || timerEndOfEpisode) && timer < 1) {
      handlePlayPause();
      setTimerOn(false);
    }
  }, [timer, timerOn, timerEndOfEpisode, handlePlayPause]);

  useEffect(() => {
    if (!playing || !timerOn) return;

    if (timerEndOfEpisode) {
      setTimer(remainingTime);
      return;
    }

    setTimer((curr) => curr - 1);
  }, [remainingTime, playing, timerEndOfEpisode, timerOn]);

  return (
    <div className="relative">
      {openLevelsModal && (
        <div className="absolute mb-4 w-40 bottom-5 right-5 translate-x-1/2 bg-brand-neutral-700 rounded text-center drop-shadow z-[50]">
          <ul role="list">
            <li
              className="py-2 text-sm border-b-2 border-b-brand-neutral-700 px-3 cursor-pointer"
              onClick={() => handleTimer(remainingTime, true)}
            >
              {formatMessage({ id: 'player.snooze.endOfEpisode' })}
            </li>
            {snoozeLevels.map((level) => (
              <li
                className="py-2 text-sm border-b-2 border-b-brand-neutral-700 last:border-b-0 px-3 cursor-pointer"
                key={level}
                onClick={() => handleTimer(level)}
              >
                {formatMessage({ id: 'player.snooze.levels' }, { minutes: level })}
              </li>
            ))}
            {timerOn && (
              <li className="py-2 text-sm cursor-pointer" onClick={handleStopTimer}>
                {formatMessage({ id: 'player.snooze.stop' })}
              </li>
            )}
          </ul>
        </div>
      )}
      <button
        className="snooze-button"
        onClick={() => setOpenLevelsModal(!openLevelsModal)}
        aria-label={formatMessage({ id: 'player.accessiblity.snooze' })}
        disabled={!currentMedia}
      >
        <Icon name="snooze" className="text-xl" />
        {timerOn && <span className="text-xs">{getReadableTimeFromSec(timer)}</span>}
      </button>
    </div>
  );
};

export const Snooze = memo(SnoozeWrapper);
