import { MutableRefObject, useEffect, useRef } from 'react';

export const useClickOutside = (callback: () => void, refs?: MutableRefObject<HTMLElement>[]) => {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent | FocusEvent) => {
      const target = event.target as Node;

      // Do nothing if the target is not connected element with document
      if (!target || !target.isConnected) {
        return;
      }

      if (
        ref.current &&
        !ref.current.contains(target) &&
        !(refs || []).some((r) => r.current?.contains(target))
      ) {
        callback();
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);
    document.addEventListener('focus', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
      document.removeEventListener('focus', handleClickOutside);
    };
  }, [callback, refs]);

  return ref;
};
