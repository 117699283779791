import { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import fr from '@/intl/fr.json';

import {
  profileRoute,
  mailtoRoute,
  suggestionFormRoute,
  websiteHelpRoute,
  websitePrivacyPolicyRoute,
  websiteTermsRoute,
} from '@/utils/routes';

const messages = {
  fr,
};

const defaultRichTextElements = {
  h3: (children) => <h3 className="text-lg">{children}</h3>,
  ul: (children) => <ul>{children}</ul>,
  li: (children) => <li>{children}</li>,
  br: () => <br />,
  profile: (children) => (
    <Link className="text-white" href={profileRoute} target="_blank">
      {children}
    </Link>
  ),
  emailMajelan: (children) => (
    <Link className="text-white" href={mailtoRoute} target="_blank">
      {children}
    </Link>
  ),
  terms: (children) => (
    <a className="text-white" href={websiteHelpRoute} target="_blank" rel="noreferrer">
      {children}
    </a>
  ),
  legalsPrivacy: (children) => (
    <Link className="text-white" href={websitePrivacyPolicyRoute} target="_blank" rel="noreferrer">
      {children}
    </Link>
  ),
  legalsUse: (children) => (
    <Link className="text-white" href={websiteTermsRoute} target="_blank" rel="noreferrer">
      {children}
    </Link>
  ),
  suggestContent: (children) => (
    <Link className="text-white" href={suggestionFormRoute} target="_blank" rel="noreferrer">
      {children}
    </Link>
  ),
};

interface IntlProviderProps {}

export const IntlProvider = ({ children }: PropsWithChildren<IntlProviderProps>) => {
  const { locale } = useRouter();

  return (
    <ReactIntlProvider
      key={locale}
      locale={locale}
      messages={messages[locale]}
      defaultRichTextElements={defaultRichTextElements}
    >
      {children}
    </ReactIntlProvider>
  );
};
