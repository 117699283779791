import { DomainTypes } from '@/types';

export class EmptyProgram {
  mediaKeyId: string;
  source: DomainTypes;
  domain: string;
  UUID: string;
  description: string;
  cover: string;

  constructor() {
    this.mediaKeyId = `A::PLACEHOLDER::PLACEHOLDER::PLACEHOLDER`;
    this.source = DomainTypes.Corporate;
    this.domain = DomainTypes.Majelan;
    this.UUID = 'EMPY-PROGRAM';
    this.cover = '/audio.svg';
    this.description = '';
  }
}
