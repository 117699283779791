import { useQuery } from '@tanstack/react-query';

import { getPrograms } from '@/modules/programs/domain/resources';

export const usePrograms = (UUIDs: string[]) => {
  const { data, isFetching } = useQuery({
    queryKey: ['programs', ...UUIDs],
    queryFn: () => getPrograms(UUIDs),
    enabled: UUIDs.length > 0,
    staleTime: Infinity,
  });

  return {
    programs: data,
    isLoadingPrograms: isFetching,
  };
};
