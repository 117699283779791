import { useQuery } from '@tanstack/react-query';

import { getUser } from '@/modules/user/domain/resources';
import { retrieveAuthSession } from '@/utils/browserStorage';

import { useAuthTokens } from './useAuthTokens';

/**
 *
 * This hook seems to be not necessary, as the user is already being fetched during login.
 * Keeping it for now.
 * The call should however be enabled if: const isEnabled = !!authSession?.UUID;
 * This also throw an error because of a corporate homepage not existing.
 */
export const useUser = () => {
  useAuthTokens();
  const authSession = retrieveAuthSession();

  const { data, error, isFetching } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUser(authSession?.UUID),
    enabled: !!authSession?.UUID,
  });

  return {
    user: data,
    userError: error,
    isLoadingUser: isFetching,
  };
};
