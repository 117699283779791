import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@/components/Icon';
import { useMediasInHistoryByProgram } from '@/hooks/useMediasInHistoryByProgram';
import { Poster } from '@/modules/player/presentation/atoms/Poster';
import { usePlayerRemoveFromQueueList } from '@/modules/player/providers/dispatch/usePlayerRemoveFromQueueList';
import { usePlayerStartMedia } from '@/modules/player/providers/dispatch/usePlayerStartMedia';
import { useProgram } from '@/modules/programs/hooks/useProgram';
import { Media, MediaHistory } from '@/types/media';
import { convertDurationMin } from '@/utils';

interface QueueListMediaProps {
  media: Media;
  reorganizeQueueList: boolean;
}

const QueueListMediaWrapper = ({ media, reorganizeQueueList }: QueueListMediaProps) => {
  const { formatMessage } = useIntl();
  const { startMedia } = usePlayerStartMedia();
  const removeFromQueueList = usePlayerRemoveFromQueueList();
  const { mediasInHistory } = useMediasInHistoryByProgram(media.programUUID);
  const mediaInHistory = mediasInHistory.find((mediaH: MediaHistory) => mediaH.UUID === media.UUID);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: media.UUID,
    disabled: !reorganizeQueueList,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  };

  const { program } = useProgram(media.programUUID);

  const handleStartMedia = () => {
    startMedia({
      media,
      lastTimeInMedia: mediaInHistory?.lastTimeInMedia || 0,
      isFromQueueList: true,
      autoPlay: true,
    });
  };

  const episodeTimeLength = convertDurationMin(media.duration);

  const episodeRemainingTimeLength = convertDurationMin(
    media.duration - (mediaInHistory?.lastTimeInMedia || 0)
  );

  const episodeDurationLabel = useCallback(
    () =>
      mediaInHistory && !(mediaInHistory?.isCompleted && mediaInHistory?.lastTimeInMedia === 0)
        ? formatMessage(
            { id: 'program.media.listening.progress' },
            { timeLength: episodeRemainingTimeLength }
          )
        : mediaInHistory?.isCompleted && mediaInHistory?.lastTimeInMedia === 0
        ? formatMessage(
            { id: 'program.media.listening.completed' },
            { timeLength: episodeTimeLength }
          )
        : episodeTimeLength,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mediaInHistory]
  );

  const handleRemoveFromQueueList = useCallback(() => {
    removeFromQueueList(media);
  }, [media, removeFromQueueList]);

  return (
    <li
      className="group w-full flex flex-row p-4 items-center cursor-pointer"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      aria-label={formatMessage(
        {
          id: 'program.media.start.media',
        },
        { media: media.title }
      )}
    >
      <Poster src={media.cover || program?.cover} alt={media.title} className="!h-14" />
      <div className="grow ms-4 max-w-full" onClick={handleStartMedia}>
        <p className="text-sm text-white line-clamp-1">{media.title}</p>
        <p className="text-xs font-light text-brand-neutral-300">{program?.title}</p>
        <p className="text-xs font-light text-brand-neutral-300">
          {formatMessage(
            {
              id: `common.program.linear.${
                mediaInHistory?.isCompleted && mediaInHistory?.lastTimeInMedia === 0
                  ? 'listened'
                  : mediaInHistory?.lastTimeInMedia > 0
                  ? 'listening'
                  : 'default'
              }.description`,
            },
            {
              episode: media.episode,
              duration: episodeDurationLabel(),
            }
          )}
        </p>
      </div>

      <div className="opacity-0 group-hover:opacity-100 max-md:opacity-100">
        {reorganizeQueueList ? (
          <Icon name="draggable" className="text-xl mx-2" />
        ) : (
          <button
            onClick={handleRemoveFromQueueList}
            aria-label={formatMessage(
              {
                id: 'player.queueList.remove.media',
              },
              { media: media.title }
            )}
          >
            <Icon
              name="close-off"
              className="text-2xl text-brand-neutral-600 hover:text-brand-neutral-100"
            />
          </button>
        )}
      </div>
    </li>
  );
};

export const QueueListMedia = memo(QueueListMediaWrapper);
