import { CorporateMediaEntity } from '@/modules/medias/domain/entities/CorporateMediaEntity';
import { corporateMediaByIdRoute } from '@/modules/medias/domain/routes/mediaRoutes';
import { CorporateMedia } from '@/types';
import { getData } from '@/utils/corpo/request/getData';

export const getMedia = async (mediaUUID: string) => {
  const response = await getData<CorporateMedia>(corporateMediaByIdRoute(mediaUUID));

  return response && new CorporateMediaEntity(response).toMedia();
};
