import { DomainCorporate } from '@/types';
import { getData } from '@/utils/corpo/request/getData';

export const getAuthnDomain = async (authnDomainId: string) => {
  try {
    const response = await getData<DomainCorporate>(`domain/${authnDomainId}`);

    return response;
  } catch (e) {
    return undefined;
  }
};
