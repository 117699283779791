export type Domain = {
  UUID: string;
  publicId: string;
  type: DomainTypes;
};

export enum DomainTypes {
  Majelan = 'majelan',
  Internal = 'internal',
  External = 'external',
  Corporate = 'corporate',
}
export type DomainCorporate = {
  id: string;
  name: string;
  oryProject: string;
  provider: string;
};
