import { UserTokens } from '@/types';

export class AuthEntity {
  UUID: string;
  authnDomainId?: string;
  email?: string;
  firebaseToken: string;
  accessToken: string;
  refreshToken: string;
  expiresAt: string;

  constructor(tokens: UserTokens) {
    this.UUID = tokens.UUID;
    this.authnDomainId = tokens.authnDomainId || tokens.corpoDomainUUID;
    this.email = tokens.email;
    this.firebaseToken = tokens.firebaseToken;
    this.accessToken = tokens.accessToken;
    this.refreshToken = tokens.refreshToken;
    this.expiresAt = tokens.expiresAt;
  }
}
