import { UserEntity } from '@/modules/user/domain/entities/UserEntity';
import { UserTokens } from '@/types';

type OnBoarding = {
  audio: boolean;
};

type Features = {
  fileToAudio: boolean;
};

export enum StorageKeys {
  AUTH = '@majelan/webapp',
  USER = '@majelan/user',
  ONBOARDING = '@majelan/onboarding',
  FEATURES = '@majelan/features',
}

const retrieveSession = (key: StorageKeys) => {
  if (typeof window === 'undefined') return;

  const rawData = localStorage.getItem(key);

  return rawData ? JSON.parse(rawData) : null;
};

export const retrieveAuthSession = (): UserTokens => retrieveSession(StorageKeys.AUTH);
export const retrieveUserSession = (): UserEntity => retrieveSession(StorageKeys.USER);
export const retrieveOnboardingSession = (): OnBoarding => retrieveSession(StorageKeys.ONBOARDING);
export const retrieveFeaturesSession = (): Features => retrieveSession(StorageKeys.FEATURES);

const setSession = (key: StorageKeys, session: UserTokens | UserEntity | OnBoarding | Features) => {
  if (typeof window === 'undefined') return;

  localStorage.setItem(key, JSON.stringify(session));
};

export const setAuthSession = async (session: UserTokens) => {
  if (typeof window === 'undefined') return;

  return setSession(StorageKeys.AUTH, session);
};

export const setUserSession = async (user: UserEntity) => {
  if (typeof window === 'undefined') return;

  setSession(StorageKeys.USER, user);
};

export const setOnboardingSession = async (onboarding: OnBoarding) => {
  if (typeof window === 'undefined') return;
  const currentOnboarding = retrieveOnboardingSession();

  setSession(StorageKeys.ONBOARDING, { ...currentOnboarding, ...onboarding });
};

export const setFeaturesSession = async (features: Features) => {
  if (typeof window === 'undefined') return;
  const currentFeatures = retrieveFeaturesSession();

  setSession(StorageKeys.FEATURES, { ...currentFeatures, ...features });
};

export const deleteSession = (key: StorageKeys) => {
  if (typeof window === 'undefined') return;

  localStorage.removeItem(key);
};
export const deleteAuthSession = () => deleteSession(StorageKeys.AUTH);
export const deleteUserSession = () => deleteSession(StorageKeys.USER);
export const deleteOnboardingSession = () => deleteSession(StorageKeys.ONBOARDING);
export const deleteFeaturesSession = () => deleteSession(StorageKeys.FEATURES);

export const deleteAllSessions = () =>
  Object.values(StorageKeys).forEach((key) => deleteSession(key));

export const clearSession = (key: StorageKeys) => {
  if (typeof window === 'undefined') return;

  localStorage.removeItem(key);
};
