import { datadogRum } from '@datadog/browser-rum';
import { isLocal } from '@/utils/request';

export class Datadog {
  private _isInitialized() {
    return (
      window.DD_RUM?.getInternalContext &&
      window.DD_RUM.getInternalContext()?.applicationId ===
        process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID
    );
  }

  init() {
    if (isLocal || this._isInitialized()) return;

    datadogRum.init({
      applicationId: `${process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID}`,
      clientToken: `${process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN}`,
      site: `${process.env.NEXT_PUBLIC_DATADOG_SITE}`,
      service: `${process.env.NEXT_PUBLIC_DATADOG_SERVICE}`,
      env: `${process.env.NEXT_PUBLIC_ENV}`,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }
}
