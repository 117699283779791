import { corporateLoginRoute } from '@/modules/auth/domain/routes/authRoutes';
import { LoginData, UserTokens } from '@/types';
import { setAuthSession } from '@/utils/browserStorage';
import { resolve } from '@/utils/corpo/request/resolve';

type loginProps = {
  email: string;
};

export const loginCorporate = async ({ email }: loginProps) => {
  if (!email) {
    console.error('loginCorporate: No email prodived');
    return null;
  }

  const domain = email.substring(email.indexOf('@') + 1);
  const response = await resolve<LoginData>(corporateLoginRoute(domain));

  if (!response) return null;

  await setAuthSession({ authnDomainId: response.id, email } as UserTokens);

  return response;
};
