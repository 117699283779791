import { useIntl } from 'react-intl';

import { Icon } from '@/components/Icon';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { useCallback, useMemo } from 'react';
import { usePlayerGoBackward } from '@/modules/player/providers/dispatch/usePlayerGoBackward';
import { usePlayerGoForward } from '@/modules/player/providers/dispatch/usePlayerGoForward';

export interface BackForwardProps {
  direction: 'backward' | 'forward';
}

export const BackForward = ({ direction }: BackForwardProps) => {
  const { formatMessage } = useIntl();
  const { canBackward, canForward } = usePlayer();
  const handleBackward = usePlayerGoBackward();
  const handleForward = usePlayerGoForward();

  const isDisabled = useMemo(
    () => (direction === 'forward' ? !canForward : !canBackward),
    [canBackward, canForward, direction]
  );

  const handleClick = useCallback(() => {
    if (direction === 'forward') {
      handleForward();
    } else {
      handleBackward();
    }
  }, [direction, handleBackward, handleForward]);

  return (
    <button
      className="backforward-button"
      onClick={handleClick}
      aria-label={formatMessage(
        { id: 'player.accessiblity.backForward' },
        { direction: direction === 'forward' ? 'avancer' : 'reculer' }
      )}
      disabled={isDisabled}
    >
      <Icon name={direction === 'forward' ? 'forward-15' : 'backward-15'} className="text-3xl" />
    </button>
  );
};
