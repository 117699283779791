import { audiosRoute } from '@/modules/audio/domain/routes/audioRoutes';
import { CorporateMediaEntity } from '@/modules/medias/domain/entities/CorporateMediaEntity';
import { requestQuery } from '@/services/request';
import { Audio, CorporateMedia } from '@/types';

export const getAudios = async (UUIDs?: string[]) => {
  const response = await requestQuery<Audio[]>({ url: audiosRoute });

  return (response || [])
    .filter(({ mediaUUID }) => !UUIDs?.length || UUIDs.includes(mediaUUID))
    .map((d) => ({
      ...d,
      media: new CorporateMediaEntity({
        type: 'audio',
        cover: '/audio.svg',
        ...d.media,
      } as unknown as CorporateMedia).toMedia(),
    }));
};
