import { chunk, flatten, fromPairs, isEmpty, join } from 'lodash';
import { ParsedUrlQuery } from 'querystring';

import { AuthActions, QueryState } from '@/types';

export const splitStateString = (state: string) =>
  (state ? fromPairs(chunk(state.split('#state=').pop().split(','), 2)) : null) as QueryState;

export const setQueryState = (type: AuthActions, query?: ParsedUrlQuery) => {
  return join(
    flatten([
      ['type', type],
      ['code', query?.code || null],
      ['continue', query?.continue || null],
      ['url_from', query?.url_from ? encodeURI(query?.url_from as string) : null],
    ])
  );
};

export const getQueryState = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const location = window.location.hash.substring(1) || window.location.search;

  const query = Object.fromEntries(new URLSearchParams(location));

  if (isEmpty(query)) return { query: null, state: null };

  const queryWithoutstate = Object.fromEntries(
    Object.entries(query).filter(([key]) => key !== 'state')
  );

  return {
    query: queryWithoutstate,
    state: splitStateString(query?.state),
  };
};
