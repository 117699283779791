import { useQuery } from '@tanstack/react-query';
import { getAuthUser } from '@/modules/auth/domain/resources/core/getAuthUser';
import { retrieveAuthSession, setAuthSession } from '@/utils/browserStorage';
import { AuthEntity } from '@/modules/auth/domain/entity/AuthEntity';

export const useAuthTokens = () => {
  const authSession = retrieveAuthSession();
  const isEnabled = !!authSession?.accessToken && !authSession.UUID;

  const { data, error, isFetching } = useQuery({
    queryKey: ['currentAuthTokens'],
    queryFn: getAuthUser,
    enabled: isEnabled,
    onSuccess: (data) => {
      setAuthSession(new AuthEntity(data));
    },
  });

  return {
    authTokens: data,
    authTokensError: error,
    authTokensLoading: isFetching,
  };
};
