import { MouseEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@/components/Icon';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { usePlayerSound } from '@/modules/player/providers/dispatch/usePlayerSound';

const SoundWrapper = () => {
  const { sound } = usePlayer();
  const handleSound = usePlayerSound();
  const volumeBarRef = useRef<HTMLDivElement>(null);
  const [volumePlayer, setVolumePlayer] = useState(sound);
  const [mouseDown, setMouseDown] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    window.addEventListener('mouseup', () => setMouseDown(false));
    return () => {
      window.removeEventListener('mouseup', () => setMouseDown(false));
    };
  }, []);

  const onClickIcon = useCallback(() => {
    if (sound > 0) {
      setVolumePlayer(sound);
      handleSound(0);
    } else {
      handleSound(volumePlayer);
    }
  }, [handleSound, sound, volumePlayer]);

  const VolumeIcon = useCallback(() => {
    if (sound === 0)
      return (
        <div data-testid="iconVolumeMute">
          <Icon name="sound-off" className="text-lg" />
        </div>
      );
    else if (sound < 0.5)
      return (
        <div data-testid="iconVolumeHalf">
          <Icon name="sound-half" className="text-lg" />
        </div>
      );
    else
      return (
        <div data-testid="iconVolumeFull">
          <Icon name="sound-on" className="text-lg" />
        </div>
      );
  }, [sound]);

  const onVolumeChange = useCallback(
    (e: MouseEvent) => {
      const bar = volumeBarRef.current;
      if (!bar) return;

      const { left, width } = bar.getBoundingClientRect();
      const distance = e.clientX - left;

      const volume = distance / width;
      handleSound(volume);
      e.stopPropagation();
    },
    [handleSound]
  );

  const onVolumeMove = useCallback(
    (e: MouseEvent) => {
      if (mouseDown) onVolumeChange(e);
    },
    [mouseDown, onVolumeChange]
  );

  const onMouseDown = useCallback(() => {
    setMouseDown(true);
  }, []);

  const onMouseUp = useCallback(() => {
    setMouseDown(false);
  }, []);

  return (
    <div className="flex gap-3 items-center" data-testid="volumeContainer">
      <button
        className="sound-button"
        onClick={onClickIcon}
        aria-label={formatMessage({ id: 'player.accessiblity.sound' })}
      >
        <VolumeIcon />
      </button>
      <div
        ref={volumeBarRef}
        className="h-1 bg-white bg-opacity-30 relative w-24 rounded cursor-pointer"
        onMouseMove={onVolumeMove}
        onClick={onVolumeChange}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        data-testid="volumeBar"
      >
        <div
          className="absolute top-0 left-0 h-full bg-white rounded"
          style={{ width: `${sound * 100}%` }}
        />
      </div>
    </div>
  );
};

export const Sound = memo(SoundWrapper);
