import { collection, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { useAuth } from '@/modules/auth/hooks/useAuth';
import { firebaseApp } from '@/services/firebase';
import { MediaHistory } from '@/types';
import { FirestoreCollections } from '@/types/firestore';

const firebaseDb = getFirestore(firebaseApp);

export const useMediasInHistoryByProgram = (programUUID: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [mediasInHistory, setMediasInHistory] = useState<MediaHistory[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    if (!user || !programUUID) return;

    const unsubscribe = onSnapshot(
      query(
        collection(
          firebaseDb,
          FirestoreCollections.Users,
          user?.UUID,
          FirestoreCollections.PlayHistory
        ),
        where('programUUID', '==', programUUID)
      ),
      (querySnapshot) => {
        setIsLoading(true);
        const mediaHistories: MediaHistory[] = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data() as MediaHistory;
          mediaHistories.push(data);
        });

        setMediasInHistory(mediaHistories);
        setIsLoading(false);
        setHasError(false);
      },
      () => {
        setIsLoading(false);
        setHasError(true);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user, programUUID]);

  return { mediasInHistory, isLoading, hasError };
};
