import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { useCallback } from 'react';

export const usePlayerSnooze = () => {
  const dispatch = usePlayerDispatch();

  const handleSnooze = useCallback(
    (snooze: boolean) => {
      dispatch({ type: PlayerReducerActions.HANDLE_SNOOZE, payload: { snooze } });
    },
    [dispatch]
  );

  return handleSnooze;
};
