import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

import { firebaseApp } from '@/services/firebase';
import { MediaHistory } from '@/types';
import { FirestoreCollections } from '@/types/firestore';

const firebaseDb = getFirestore(firebaseApp);

export const getMediaInProgress = async (mediaUUID: string) => {
  const { currentUser } = getAuth();

  if (!currentUser?.uid || !mediaUUID) return {};

  const mediaInProgressRef = doc(
    firebaseDb,
    FirestoreCollections.Users,
    currentUser?.uid,
    FirestoreCollections.PlayHistory,
    mediaUUID
  );

  try {
    const storedMediaInProgress = (await getDoc(mediaInProgressRef)).data() as MediaHistory;

    const storedLastTimeInMedia = storedMediaInProgress?.lastTimeInMedia || 0;
    const storedMaxTimeInMedia = storedMediaInProgress?.maxTimeInMedia || 0;
    const currentMaxTimeInMedia = Math.max(storedMaxTimeInMedia, storedLastTimeInMedia);

    return {
      storedMediaInProgress,
      storedLastTimeInMedia,
      storedMaxTimeInMedia,
      currentMaxTimeInMedia,
    };
  } catch (error) {
    return {
      storedMediaInProgress: {},
      storedLastTimeInMedia: 0,
      storedMaxTimeInMedia: 0,
      currentMaxTimeInMedia: 0,
    };
  }
};
