import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@/components/Icon';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { usePlayerQueueListDisplay } from '@/modules/player/providers/dispatch/usePlayerQueueListDisplay';

export const QueueListButton = () => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { queueListDisplayed } = usePlayer();
  const handleQueueListDisplay = usePlayerQueueListDisplay();

  useEffect(() => {
    handleQueueListDisplay(false);
  }, [router, handleQueueListDisplay]);

  const handleDisplayQueueList = () => {
    handleQueueListDisplay(!queueListDisplayed);
  };

  return (
    <button
      className={classNames('queuelist-button', { 'text-primary': queueListDisplayed })}
      onClick={handleDisplayQueueList}
      aria-label={formatMessage({
        id: `player.accessibility.queueList.${queueListDisplayed ? 'close' : 'open'}`,
      })}
    >
      <Icon name="queue-list" className="text-3xl" />
    </button>
  );
};
