import { getAuth } from 'firebase/auth';
import { doc, getFirestore, increment, setDoc } from 'firebase/firestore';

import { firebaseApp } from '@/services/firebase';
import { FirestoreCollections } from '@/types/firestore';

const firebaseAuth = getAuth();
const firebaseDb = getFirestore(firebaseApp);

export const saveUserListeningIncrement = async (listeningIncrement: number) => {
  const userUUID = firebaseAuth.currentUser?.uid || '';

  if (!userUUID) return;

  const userDoc = doc(firebaseDb, FirestoreCollections.Users, userUUID);

  setDoc(
    userDoc,
    {
      total: increment(listeningIncrement || 20),
    },
    { merge: true }
  );
};
