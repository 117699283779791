import { useQueryClient } from '@tanstack/react-query';
import {
  collection,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { useAuth } from '@/modules/auth/hooks/useAuth';
import { firebaseApp } from '@/services/firebase';
import { MediaHistory } from '@/types';
import { FirestoreCollections } from '@/types/firestore';

const firebaseDb = getFirestore(firebaseApp);

export const useMediasInProgress = (mediasLimit = 0) => {
  const [isLoading, setIsLoading] = useState(true);
  const [mediasInProgress, setMediasInProgress] = useState<MediaHistory[]>([]);
  const [error, setError] = useState(false);
  const queryClient = useQueryClient();
  const { user } = useAuth();

  useEffect(() => {
    if (!user?.UUID) return;

    const queryConstraints = [
      orderBy('lastTimestamp', 'desc'),
      where('lastTimestamp', '>', DateTime.now().minus({ months: 1 }).toMillis()),
      where('isDeleted', '==', false),
      ...(mediasLimit ? [limit(mediasLimit)] : []),
    ];

    const unsubscribe = onSnapshot(
      query(
        collection(
          firebaseDb,
          FirestoreCollections.Users,
          user.UUID,
          FirestoreCollections.PlayHistory
        ),
        ...queryConstraints
      ),
      (querySnapshot) => {
        setIsLoading(true);
        const mediaHistories: MediaHistory[] = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data() as MediaHistory;
          if (data.lastTimeInMedia !== 0) {
            mediaHistories.push(data);
          }
        });
        setMediasInProgress(mediaHistories);
        setIsLoading(false);
        setError(false);
      },
      () => {
        setIsLoading(false);
        setError(true);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user?.UUID, mediasLimit]);

  useEffect(() => {
    queryClient.setQueryData(['mediasInProgress', mediasLimit], mediasInProgress);
  }, [mediasInProgress, queryClient, mediasLimit]);

  return { mediasInProgress, error, isLoading };
};
