import { useCallback } from 'react';

import { useAnalyticContext } from '@/modules/analytic/providers/AnalyticProvider';
import { useAuth } from '@/modules/auth/hooks/useAuth';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { getMediaInProgress } from '@/services/firestore/getMediaInProgress';
import { ANALYTICS_KEYS, EventSources, Media, Typology } from '@/types';

export const useTrackPlayer = () => {
  const { user } = useAuth();
  const { track } = useAnalyticContext();
  const { currentMedia, currentProgram, speed } = usePlayer();

  const trackPlayerSnooze = useCallback(
    (endOfEpisode: boolean, level: number) => {
      track(ANALYTICS_KEYS.PLAYER_SNOOZE, {
        min_duration: endOfEpisode ? 'end_media' : level.toString(),
      });
    },
    [track]
  );

  const trackPlayerSpeed = useCallback(() => {
    track(ANALYTICS_KEYS.PLAYER_SPEED, {
      mediaIdentifierKey: currentMedia.mediaKeyId,
      contentOrigin: currentMedia.source,
      domainID: currentMedia.domain,
      media_duration: currentMedia.duration,
      mediaUUID: currentMedia.UUID,
      selected_speed: speed,
    });
  }, [currentMedia, speed, track]);

  const trackPlayerGoBackward = useCallback(() => {
    track(ANALYTICS_KEYS.PLAYER_BACKWARD, {
      mediaIdentifierKey: currentMedia.mediaKeyId,
    });
  }, [track, currentMedia]);

  const trackPlayerGoForward = useCallback(() => {
    track(ANALYTICS_KEYS.PLAYER_FORWARD, {
      mediaIdentifierKey: currentMedia.mediaKeyId,
    });
  }, [track, currentMedia]);

  const trackPlayerGoNext = useCallback(
    (nextMedia: Media) => {
      track(ANALYTICS_KEYS.PLAYER_NEXT, {
        mediaIdentifierKey: currentMedia.mediaKeyId,
        contentOrigin: currentMedia.source,
        domainID: currentMedia.domain,
        media_duration: currentMedia.duration,
        media_title: currentMedia.title,
        media_order: currentMedia.episode,
        mediaUUID: currentMedia.UUID,
        programUUID: currentMedia.programUUID,
        program_title: currentProgram?.title,
        target_mediaUUID: nextMedia.UUID,
        target_media_title: nextMedia.title,
        target_programUUID: nextMedia.programUUID,
        target_program_title: nextMedia.programUUID,
      });
    },
    [currentMedia, currentProgram, track]
  );

  const trackPlayerPlay = useCallback(() => {
    if (!currentMedia) return;

    track(ANALYTICS_KEYS.PLAYER_PLAY, {
      mediaIdentifierKey: currentMedia.mediaKeyId,
      contentOrigin: currentMedia.source,
      domainID: currentMedia.domain,
      media_duration: currentMedia.duration,
      mediaUUID: currentMedia.UUID,
      media_order: currentMedia.episode,
      media_status: currentMedia.status,
      programUUID: currentMedia.programUUID,
      program_typology: currentProgram?.typology || Typology.Episodic,
      program_title: currentProgram?.title,
      is_premium: user?.premium ? 1 : 0,
      source: EventSources.Player,
    });
  }, [currentMedia, currentProgram, user?.premium, track]);

  const trackPlayerPause = useCallback(() => {
    track(ANALYTICS_KEYS.PLAYER_PAUSE, {
      mediaIdentifierKey: currentMedia.mediaKeyId,
      contentOrigin: currentMedia.source,
      domainID: currentMedia.domain,
      media_duration: currentMedia.duration,
      mediaUUID: currentMedia.UUID,
    });
  }, [currentMedia, track]);

  const trackPlayerView = useCallback(() => {
    track(
      ANALYTICS_KEYS.BROWSE_VIEW_PLAYER,
      {
        mediaIdentifierKey: currentMedia.mediaKeyId,
        contentOrigin: currentMedia.source,
        domainID: currentMedia.domain,
        media_duration: currentMedia.duration,
        mediaUUID: currentMedia.UUID,
      },
      true,
      true
    );
  }, [currentMedia, track]);

  const trackStreamPlay = useCallback(
    async (timeInMedia = 0) => {
      try {
        if (!currentMedia) return;

        const mediaInProgress = await getMediaInProgress(currentMedia?.UUID);
        if (!mediaInProgress) return;

        const { storedLastTimeInMedia, currentMaxTimeInMedia } = mediaInProgress;

        track(ANALYTICS_KEYS.STREAM_PLAY, {
          contentOrigin: currentMedia.source,
          domainID: currentMedia.domain,
          is_premium: user?.premium ? 1 : 0,
          media_title: currentMedia.title,
          mediaUUID: currentMedia.UUID,
          media_order: currentMedia.episode,
          media_max_position: currentMaxTimeInMedia,
          media_position: storedLastTimeInMedia,
          media_duration: currentMedia.duration,
          media_status: currentMedia.status,
          mediaIdentifierKey: currentMedia.mediaKeyId,
          program_title: currentProgram?.title,
          program_typology: currentProgram?.typology || Typology.Episodic,
          programUUID: currentProgram?.UUID,
          time_in_media: timeInMedia,
          userUUID: user.UUID,
        });
      } catch (error) {
        console.error('Error tracking stream play');
      }
    },
    [currentMedia, currentProgram, user, track]
  );

  return {
    trackPlayerSnooze,
    trackPlayerSpeed,
    trackPlayerGoBackward,
    trackPlayerGoForward,
    trackPlayerGoNext,
    trackPlayerPlay,
    trackPlayerPause,
    trackPlayerView,
    trackStreamPlay,
  };
};
