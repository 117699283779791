import '@etx-majelan/tailwind-config/dist/index.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'lazysizes';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
import { Manrope } from 'next/font/google';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { Fragment, useEffect, useState } from 'react';

import { BannerConsent } from '@/components/BannerConsent';
import { Head } from '@/components/Head';
import { usePreventDropFile } from '@/hooks/usePreventDropFile';
import { AnalyticProvider } from '@/modules/analytic/providers/AnalyticProvider';
import LayoutAuth from '@/modules/auth/presentation/organisms/LayoutAuth';
import { AuthProvider } from '@/modules/auth/providers/AuthProvider';
import { RedirectProvider } from '@/modules/auth/providers/RedirectProvider';
import { NotificationsProvider } from '@/modules/notifications/providers/NotificationsProvider';
import { Player } from '@/modules/player/presentation/Player';
import { PlayerProvider } from '@/modules/player/providers/PlayerProvider';
import { ConnectionProvider } from '@/providers/ConnectionProvider';
import { FirebaseAuthProvider } from '@/providers/FirebaseAuthProvider';
import { IntlProvider } from '@/providers/IntlProvider';
import { Datadog } from '@/services/datadog';
import '@/services/firebase';
import '@/styles/global.scss';
import { queryClient } from '@/utils/request';
import {
  loginRoute,
  passwordForgottenRoute,
  passwordResetRoute,
  signupRoute,
} from '@/utils/routes';

const manrope = Manrope({ subsets: ['latin'], variable: '--font-manrope' });

export default function WebApp({ Component, pageProps }: AppProps) {
  usePreventDropFile();
  const [render, setRender] = useState(false);
  const { pathname } = useRouter();
  const Layout = [loginRoute, signupRoute, passwordForgottenRoute, passwordResetRoute].includes(
    pathname
  )
    ? LayoutAuth
    : Fragment;

  /** @todo: This hacks Window is not defined in Next.js React app */
  useEffect(() => {
    setRender(true);

    new Datadog().init();
  }, []);

  return !render ? null : (
    <div className={`${manrope.variable} font-sans`}>
      <ThemeProvider attribute="class" forcedTheme="dark">
        <IntlProvider>
          <Head />
          <BannerConsent />
          <QueryClientProvider client={queryClient}>
            <NotificationsProvider>
              <ConnectionProvider>
                <AuthProvider>
                  <RedirectProvider>
                    <FirebaseAuthProvider>
                      <AnalyticProvider>
                        <div id="accountDeletionPortal" />
                        <div id="onboardingPortal" />
                        <PlayerProvider>
                          <div id="genericPortal" />
                          <Player />

                          <Layout>
                            <Component {...pageProps} />
                          </Layout>
                          <Script
                            id="google-analytics"
                            async
                            defer
                            type="text/javascript"
                            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG_ID}`}
                          />
                          <Script id="google-analytics-2">
                            {`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', '${process.env.NEXT_PUBLIC_GTAG_ID}')
                    `}
                          </Script>
                          <Script id="facebook-pixel">{`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;t.defer=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '${process.env.NEXT_PUBLIC_FB_BUSINESS_ID}');
                    fbq('track', 'PageView');
                  `}</Script>
                        </PlayerProvider>
                      </AnalyticProvider>
                    </FirebaseAuthProvider>
                  </RedirectProvider>
                </AuthProvider>
              </ConnectionProvider>
            </NotificationsProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </IntlProvider>
      </ThemeProvider>
    </div>
  );
}
