import { Configuration, FrontendApi } from '@ory/client';
import { edgeConfig } from '@ory/integrations/next';
import axios from 'axios';

import { DomainCorporate } from '@/types';

const instance = axios.create();

instance.interceptors.request.use(
  function (config) {
    config.url = config.url?.replace('/api', '');
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const ory: Record<string, FrontendApi> = {};

export const getOryInstance = (domain: DomainCorporate) => {
  if (domain && ory[domain.name]) return ory[domain.name];

  const conf = new Configuration(edgeConfig);
  conf.basePath = domain?.oryProject;
  conf.baseOptions = { withCredentials: true };

  //eslint-disable-next-line
  const oryInstance = new FrontendApi(conf, undefined, instance as any);
  if (domain) ory[domain.name] = oryInstance;

  return oryInstance;
};

export default ory;
