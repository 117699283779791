import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@/components/Icon';
import { useNotifications } from '@/modules/notifications/hooks/useNotifications';
import { NotificationTypes } from '@/types/notifications';

const DISPLAY_DURATION = 4000;

export const Notifications = () => {
  const { notification, hideNotification } = useNotifications();

  const { formatMessage } = useIntl();

  const [notificationDisplayed, setNotificationDisplayed] = useState(
    !!notification?.title || !!notification?.message
  );
  const [notificationAnimated, setNotificationAnimated] = useState(false);
  const timeoutRef = useRef(null);

  const handleDismiss = useCallback(() => {
    setNotificationDisplayed(false);
    setNotificationAnimated(false);
    hideNotification();
  }, [hideNotification]);

  useEffect(() => {
    if (!notificationDisplayed && notification) {
      setNotificationAnimated(true);
      setNotificationDisplayed(true);
      timeoutRef.current = setTimeout(handleDismiss, DISPLAY_DURATION);
    }
  }, [notification, notificationDisplayed, handleDismiss]);

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(handleDismiss, DISPLAY_DURATION);
  };

  return (
    notification &&
    notificationDisplayed && (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classNames('notification', { 'notification-animated': notificationAnimated })}
        aria-modal="true"
        role="dialog"
      >
        <div
          className={classNames({
            'bg-danger': notification?.type === NotificationTypes.Error,
          })}
        >
          <button
            className="notification-close"
            onClick={handleDismiss}
            aria-label={formatMessage({ id: 'notification.accessibility.close' })}
          >
            <Icon name="close" />
          </button>
          <h3 className="notification-title">{notification?.title}</h3>
          <span className="notification-message">{notification?.message}</span>
        </div>
      </div>
    )
  );
};
