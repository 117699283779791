import { MediaEntity } from '@/modules/medias/domain/entities/MediaEntity';
import { coreMediasByProgramIdRoute } from '@/modules/medias/domain/routes/mediaRoutes';
import { Media } from '@/types';
import { requestQuery } from '@/services/request';

export const getQueueList = async (
  programUUID: string,
  episode: number,
  remainingEpisodes?: number
) => {
  try {
    if (!episode && episode !== 0) return [];

    const response = await requestQuery<{ media: Media; permanentPlaybackURL: string }[]>({
      url: coreMediasByProgramIdRoute(programUUID),
      params: {
        episode,
        ...(remainingEpisodes && { limit: remainingEpisodes }),
      },
    });

    return (response || []).map(
      (d) => new MediaEntity({ ...d.media, playbackURL: d.permanentPlaybackURL }) as Media
    );
  } catch (e) {
    return [];
  }
};
