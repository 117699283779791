import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { useCallback } from 'react';

export const usePlayerStop = () => {
  const dispatch = usePlayerDispatch();

  const handleStop = useCallback(() => {
    dispatch({ type: PlayerReducerActions.HANDLE_STOP });
  }, [dispatch]);

  return handleStop;
};
