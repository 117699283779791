import { ReactElement, useEffect, useState } from 'react';

import { useNotifications } from '@/modules/notifications/hooks/useNotifications';

interface ConnectionProviderProps {
  children: ReactElement | null;
}

export const ConnectionProvider = ({ children }: ConnectionProviderProps) => {
  const { showNotificationError } = useNotifications();

  const [isOnline, setIsOnline] = useState(
    typeof window !== 'undefined' ? window.navigator.onLine : true
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const handleIsConnected = () => setIsOnline(true);
    const handleIsDisconnected = () => setIsOnline(false);

    window.addEventListener('offline', handleIsDisconnected);

    window.addEventListener('online', handleIsConnected);

    return () => {
      window.removeEventListener('offline', handleIsDisconnected);
      window.removeEventListener('online', handleIsConnected);
    };
  }, []);

  if (!isOnline) {
    showNotificationError('common.connection.offline.description');
  }

  return children;
};
