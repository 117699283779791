import packageJson from '../../package.json';

export const config = {
  version: packageJson.version,
  majelanWebsite: process.env.NEXT_PUBLIC_MAJELAN_WEBSITE,
  majelanWebsiteHelp: process.env.NEXT_PUBLIC_MAJELAN_WEBSITE_HELP,
  majelanWebsiteGo: process.env.NEXT_PUBLIC_MAJELAN_WEBSITE_GO,
  majelanWebapp: process.env.NEXT_PUBLIC_MAJELAN_WEBAPP,
  majelanApi: process.env.NEXT_PUBLIC_MAJELAN_API,
  segmentApiKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
};
