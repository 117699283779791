import { getOryInstance } from '@/utils/pkg/sdk';

import { getAuthnDomain } from './getAuthnDomain';

export const logout = async (authnDomainId: string) => {
  if (!authnDomainId) return;

  const domain = await getAuthnDomain(authnDomainId);
  if (domain) {
    const { data: flow } = await getOryInstance(domain).createBrowserLogoutFlow();
    await getOryInstance(domain).updateLogoutFlow({
      token: flow.logout_token,
    });
  }
};
