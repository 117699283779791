import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { useCallback } from 'react';

export const usePlayerQueueListDisplay = () => {
  const dispatch = usePlayerDispatch();

  const handleQueueListDisplay = useCallback(
    (queueListDisplayed: boolean) => {
      dispatch({
        type: PlayerReducerActions.HANDLE_DISPLAY_QUEUELIST,
        payload: { queueListDisplayed },
      });
    },
    [dispatch]
  );

  return handleQueueListDisplay;
};
