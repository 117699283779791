import { useIntl } from 'react-intl';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { usePlayerSpeed } from '@/modules/player/providers/dispatch/usePlayerSpeed';

export const Speed = () => {
  const { formatMessage } = useIntl();
  const { speed, currentMedia } = usePlayer();
  const handleSpeed = usePlayerSpeed();

  return (
    <button
      className="speed-button"
      onClick={handleSpeed}
      aria-label={formatMessage({ id: 'player.accessiblity.speed' })}
      disabled={!currentMedia}
    >
      <span>{speed}x</span>
    </button>
  );
};
