import { CorporateProgramEntity } from '@/modules/programs/domain/entities/CorporateProgramEntity';
import { corporateProgramByIdRoute } from '@/modules/programs/domain/routes/core/programsRoutes';
import { CorporateProgram } from '@/types';
import { getData } from '@/utils/corpo/request/getData';

export const getProgram = async (programUUID: string) => {
  if (!programUUID) throw new Error('no programUUID provided');

  const response = await getData<CorporateProgram>(corporateProgramByIdRoute(programUUID));

  return response && new CorporateProgramEntity(response).toProgram();
};
