import { requestQuery } from '@/services/request';
import { coreUserByIdRoute } from '../routes/userRoutes';

export const deleteUser = async (userId: string) => {
  if (!userId) return;

  try {
    await requestQuery<void>({
      method: 'DELETE',
      url: coreUserByIdRoute(userId),
    });
  } catch (e) {
    return null;
  }
};
