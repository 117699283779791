import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { fetchAndActivate, getRemoteConfig, getString } from 'firebase/remote-config';
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';

import { useAuth } from '@/modules/auth/hooks/useAuth';
import { useRefreshFirebaseTokens } from '@/modules/auth/hooks/useRefreshFirebaseTokens';
import { firebaseApp } from '@/services/firebase';
import { saveMediaHistory } from '@/services/firestore/saveMediaHistory';
import { setFeaturesSession } from '@/utils/browserStorage';

const firebaseAuth = getAuth();

export const FirebaseContext = createContext({ saveMediaHistory });

FirebaseContext.displayName = 'FirebaseContext';

interface FirebaseAuthProviderProps {}

export const FirebaseAuthProvider = ({
  children,
}: PropsWithChildren<FirebaseAuthProviderProps>) => {
  const auth = useRefreshFirebaseTokens();
  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      if (!auth?.firebaseToken) return;

      try {
        await signInWithCustomToken(firebaseAuth, auth.firebaseToken);
      } catch (error) {
        console.error('FirebaseAuthProvider error:', error, auth.firebaseToken);
      }
    })();
  }, [auth?.firebaseToken]);

  useEffect(() => {
    if (!user?.corpoDomainUUID) return;

    (async () => {
      const remoteConfig = getRemoteConfig(firebaseApp);

      try {
        await fetchAndActivate(remoteConfig);
        const fileToAudioDomainWhitelist = JSON.parse(
          getString(remoteConfig, 'fileToAudioDomainWhitelist')
        );

        const fileToAudioAllowed = (fileToAudioDomainWhitelist || []).includes(
          user.corpoDomainUUID
        );
        setFeaturesSession({ fileToAudio: fileToAudioAllowed });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [user?.corpoDomainUUID]);

  const providerState = {
    saveMediaHistory,
  };

  return <FirebaseContext.Provider value={providerState}>{children}</FirebaseContext.Provider>;
};

export function useFirebaseContext() {
  return useContext(FirebaseContext);
}
