import { useCallback } from 'react';

import { useTrackPlayer } from '@/modules/analytic/hooks/useTrackPlayer';
import { usePlayer, usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { Media } from '@/types';

export const usePlayerPlaying = () => {
  const { playing, currentMedia } = usePlayer();
  const dispatch = usePlayerDispatch();
  const { trackPlayerPlay, trackPlayerPause, trackStreamPlay } = useTrackPlayer();

  const handlePlayPause = useCallback(() => {
    if (!currentMedia?.UUID) return;

    dispatch({ type: PlayerReducerActions.HANDLE_PLAYING });

    if (!playing) {
      trackPlayerPlay();
      trackStreamPlay(0);
    } else {
      trackPlayerPause();
    }
  }, [playing, currentMedia?.UUID, dispatch, trackPlayerPause, trackPlayerPlay, trackStreamPlay]);

  const handlePlay = useCallback(
    (media?: Media) => {
      const mediaToPlay = media || currentMedia;
      if (playing || !mediaToPlay?.UUID) return;

      dispatch({ type: PlayerReducerActions.HANDLE_START });

      trackPlayerPlay();
      trackStreamPlay(0);
    },
    [playing, currentMedia, dispatch, trackPlayerPlay, trackStreamPlay]
  );

  const handlePause = useCallback(() => {
    if (!playing || !currentMedia?.UUID) return;

    dispatch({ type: PlayerReducerActions.HANDLE_STOP });

    trackPlayerPause();
  }, [playing, currentMedia?.UUID, dispatch, trackPlayerPause]);

  return { handlePlayPause, handlePlay, handlePause };
};
