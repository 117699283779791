import { coreRefreshTokenRoute } from '@/modules/auth/domain/routes/authRoutes';
import { UserTokens } from '@/types';
import ky from 'ky';
import { config } from '@/utils';
import { retrieveAuthSession } from '@/utils/browserStorage';

/**
 *
 * cookie.maj.refreshToken is required here
 */
export const getRefreshToken = async (): Promise<UserTokens> => {
  const authSession = retrieveAuthSession();

  try {
    const response = await ky(coreRefreshTokenRoute, {
      prefixUrl: config.majelanApi,
      headers: {
        Accept: `application/com.majelan.v3`,
        Authorization: `Bearer ${authSession?.accessToken}`,
        'Content-Type': 'application/json',
        'majelan-app-version': `webapp/${config.version}`,
      },
      credentials: 'include',
      method: 'POST',
    });

    return response.json();
  } catch (error) {
    console.error('Error while refreshing token', error);
    return null;
  }
};
