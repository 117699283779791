import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { usePlayer, usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { useTrackPlayer } from '@/modules/analytic/hooks/useTrackPlayer';
import { useCallback } from 'react';

const speedLevels = [0.5, 0.7, 1, 1.2, 1.3, 1.5, 1.7, 2];

export const usePlayerSpeed = () => {
  const dispatch = usePlayerDispatch();
  const { speed } = usePlayer();
  const { trackPlayerSpeed } = useTrackPlayer();

  const handleSpeed = useCallback(() => {
    const currentIndex = speedLevels.indexOf(speed);
    const nextIndex = (currentIndex + 1) % speedLevels.length;

    dispatch({
      type: PlayerReducerActions.HANDLE_SPEED,
      payload: { speed: speedLevels[nextIndex] },
    });
    trackPlayerSpeed();
  }, [dispatch, speed, trackPlayerSpeed]);

  return handleSpeed;
};
