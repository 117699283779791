import Stripe from 'stripe';

export enum SubscriptionTypes {
  Corporate = 'corporate',
  Stripe = 'stripe',
  B2b = 'b2b',
  Playstore = 'playstore',
}

export type Subscription = {
  UUID: string;
  userUUID: string;
  subscriptionID: string;
  appLastTransactionDate: Date | string;
  plan: 'corporate';
  type: SubscriptionTypes;
  expirationDate: Date | string;
  renewalOn: Date | string;
  cancelPremiumDate: Date | string;
  upgradePremiumDate: Date | string;
  isPremium: boolean;
  receiptData: string;
  couponCode: '';
  couponStripeID: null;
  pcStripeID: '';
  hasCoupon: boolean;
  offerUUID: string;
  externalID: '';
  trialStartDate: Date | string;
  trialEndDate: Date | string;
  enabled: boolean;
  createdAt: Date | string;
  modifiedAt: Date | string;
  priceID?: string;
  isTrialPremium?: boolean;
  partner?: {
    name: string;
  };
};

export interface SubscriptionConfig {
  i18n: {
    fr: Record<string, string>;
    en: Record<string, string>;
  };
  productId: string;
  trialPeriod: number;
  isFamous: boolean;
}

export interface ProductWithPrices extends Stripe.Product {
  prices: Stripe.Price[];
}

export type StripeProduct = {
  id: string;
  metadata: Record<string, string>;
  prices: {
    id: string;
    metadata: Record<string, string>;
    currency: string;
    unit_amount: number;
    recurring: Stripe.Price.Recurring;
  }[];
};
