import { User } from '@/types';
import { requestQuery } from '@/services/request';
import { coreUserByIdRoute } from '../routes/userRoutes';

export const updateUser = async (userId: string, data: Partial<User>) => {
  if (!userId) return;

  try {
    const response = await requestQuery<User>({
      method: 'PUT',
      url: coreUserByIdRoute(userId),
      data,
    });

    return response;
  } catch (e) {
    return null;
  }
};
