/** API Core */
export const coreMediasRoute = `medias/get`;
export const coreMediaByIdRoute = (mediaUUID: string) => `medias/${mediaUUID}`;
export const coreMediasByProgramIdRoute = (programUUID: string) =>
  `player/programs/${programUUID}/medias`;

/** API Corporate */
export const corporateMediasRoute = () => 'medias';
export const corporateMediasByIdRoute = 'medias/resolve';
export const corporateMediasByProgramIdRoute = (programUUID: string) => `programs/${programUUID}`;
export const corporateMediaByIdRoute = (mediaUUID: string) => `medias/${mediaUUID}`;
