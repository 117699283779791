import { PlayerReducerActions } from '@/modules/player/providers/PlayerProvider.types';
import { playerRef, usePlayer, usePlayerDispatch } from '@/modules/player/providers/PlayerProvider';
import { useTrackPlayer } from '@/modules/analytic/hooks/useTrackPlayer';
import { MouseEvent, useCallback } from 'react';
import { useFirebaseContext } from '@/providers/FirebaseAuthProvider';
import { MediaHistory } from '@/types';
import { usePlayerEnded } from './usePlayerEnded';

export const usePlayerSeekMouseUp = () => {
  const { currentMedia } = usePlayer();
  const dispatch = usePlayerDispatch();
  const { trackStreamPlay } = useTrackPlayer();
  const { saveMediaHistory } = useFirebaseContext();
  const handleEnded = usePlayerEnded();

  /** @todo: check types */
  const handleSeekMouseUp = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: MouseEvent<HTMLInputElement> | any) => {
      playerRef.current.seekTo(parseFloat(e.target.value));

      const isPlayerValueAtMax = Number(e.target.value) === 1;
      const currentPlayedSeconds = playerRef.current.getCurrentTime();
      const mediaDuration = currentMedia.duration;

      if (isPlayerValueAtMax) {
        handleEnded();
      }

      saveMediaHistory({
        ...currentMedia,
        lastTimeInMedia: isPlayerValueAtMax ? mediaDuration : currentPlayedSeconds,
      } as unknown as MediaHistory);

      const timeInMedia = isPlayerValueAtMax ? mediaDuration : currentPlayedSeconds;
      trackStreamPlay(timeInMedia);

      dispatch({ type: PlayerReducerActions.HANDLE_SEEK_MOUSE_UP });
    },
    [currentMedia, dispatch, handleEnded, saveMediaHistory, trackStreamPlay]
  );

  return handleSeekMouseUp;
};
