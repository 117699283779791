import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { DateTime } from 'luxon';

import { firebaseApp } from '@/services/firebase';
import { ANALYTICS_KEYS, MediaHistory } from '@/types';
import { FirestoreCollections } from '@/types/firestore';
import { removeNils } from '@/utils';

import { getMediaInProgress } from './getMediaInProgress';
import { saveProgramHistory } from './saveProgramHistory';
import { saveUserListeningIncrement } from './saveUserListeningIncrement';

const firebaseDb = getFirestore(firebaseApp);

const fetchOldHistory = async (userUUID: string, mediaUUID: string) => {
  if (!firebaseDb) return;

  try {
    const docRef = doc(
      firebaseDb,
      FirestoreCollections.Users,
      userUUID,
      FirestoreCollections.PlayHistory,
      mediaUUID
    );
    const docSnap = await getDoc(docRef);
    return docSnap.data() as MediaHistory;
  } catch {
    return null;
  }
};

const calculateCompletionRate = (nbStreamByte, duration) => {
  return Number(((nbStreamByte * 10) / duration).toFixed(2));
};

const determineCompletionStatus = (lastTimeInMedia, duration, oldHistory) => {
  const isAtEndOfMedia = lastTimeInMedia >= duration - ANALYTICS_KEYS.STREAM_PLAY_INTERVAL;
  const isCompleted = oldHistory.isCompleted || isAtEndOfMedia;
  const isCompletedAndNotRestarted = isCompleted && lastTimeInMedia === 0;
  return { isAtEndOfMedia, isCompleted, isCompletedAndNotRestarted };
};

export const saveMediaHistory = async (mediaHistory: MediaHistory) => {
  try {
    if (!firebaseDb) return;

    const { currentUser } = getAuth();
    const userUUID = currentUser?.uid;
    if (!userUUID) return;

    const mediaInProgress = await getMediaInProgress(mediaHistory?.UUID || '');
    if (!mediaInProgress) return;

    const { storedMaxTimeInMedia } = mediaInProgress;
    const { duration, episode, programUUID, UUID, isDeleted } = mediaHistory;

    let oldHistory: MediaHistory = {
      completionRate: 0,
      duration,
      episode,
      isCompleted: false,
      isCompletedAndNotRestarted: false,
      isDeleted,
      lastCompletionDate: null,
      lastTimeInMedia: 0,
      lastTimestamp: 0,
      markedAsRead: false,
      maxTimeInMedia: 0,
      nbStreamByte: 0,
      programUUID,
      userUUID,
      UUID,
    };

    const existingHistory = await fetchOldHistory(userUUID, UUID);
    if (existingHistory) {
      oldHistory = existingHistory;
    }

    const lastTimeInMedia = mediaHistory.lastTimeInMedia || oldHistory.lastTimeInMedia || 0;
    const maxTimeInMedia = Math.max(storedMaxTimeInMedia, lastTimeInMedia);
    const nbStreamByte = oldHistory.nbStreamByte + 1;
    const completionRate = calculateCompletionRate(nbStreamByte, duration);

    const { isAtEndOfMedia, isCompleted, isCompletedAndNotRestarted } = determineCompletionStatus(
      lastTimeInMedia,
      duration,
      oldHistory
    );

    const lastCompletionDate = isAtEndOfMedia
      ? DateTime.now().toMillis()
      : oldHistory.lastCompletionDate || null;
    const shouldCompleteMedia = isCompleted && isAtEndOfMedia && !!oldHistory.lastTimeInMedia;
    const incrementTime = isAtEndOfMedia
      ? 0
      : Math.max(0, lastTimeInMedia - oldHistory.lastTimeInMedia);

    const nextHistory: MediaHistory = removeNils({
      completionRate,
      lastTimeInMedia: isAtEndOfMedia ? 0 : lastTimeInMedia,
      duration,
      episode,
      isCompleted,
      isCompletedAndNotRestarted,
      isDeleted: false,
      lastCompletionDate,
      lastTimestamp: DateTime.now().toMillis(),
      maxTimeInMedia,
      nbStreamByte,
      programUUID,
      userUUID,
      UUID,
    });

    const docHistory = doc(
      firebaseDb,
      FirestoreCollections.Users,
      userUUID,
      FirestoreCollections.PlayHistory,
      UUID
    );
    await setDoc(docHistory, nextHistory, { merge: true });

    saveUserListeningIncrement(incrementTime);

    if (shouldCompleteMedia) {
      saveProgramHistory(nextHistory);
    }
  } catch (error) {
    console.error('Error saving media history');
  }
};
