/** API Core */
export const coreMeRoute = 'me';
export const coreAskResetPasswordRoute = 'users/password/reset';
export const coreResetPasswordRoute = 'users/password';
export const coreLoginRoute = 'users/login';
export const coreLogoutRoute = 'users/logout';
export const coreMagicLinkRoute = 'users/link';
export const coreSignupRoute = 'users';
export const coreCheckSSORoute = 'sso/verify-domain';
export const coreRefreshTokenRoute = 'token/refresh';
export const coreCustomerPortalRoute = 'customer_portal';
export const resolveRoute = 'resolve';

/** API Corporate */
export const corporateLoginRoute = (domain: string) => `resolve?domain=${domain}`;
