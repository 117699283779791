import { MediaEntity } from '@/modules/medias/domain/entities/MediaEntity';
import { coreMediasByProgramIdRoute } from '@/modules/medias/domain/routes/mediaRoutes';
import { requestQuery } from '@/services/request';
import { MediaPlayBackUrl } from '@/types';

export const getMediasProgram = async (programUUID: string) => {
  if (!programUUID) return;

  const response = await requestQuery<MediaPlayBackUrl[]>({
    url: coreMediasByProgramIdRoute(programUUID),
  });

  return (response || []).map(
    (d) =>
      new MediaEntity({
        ...d.media,
        domain: d.source,
        playbackURL: d.permanentPlaybackURL,
      })
  );
};
