import { isAuthenticatedAsCorporate } from '@/modules/auth/utils/isAuthenticatedAsCorporate';
import { getMedias as getMediasCore } from '@/modules/medias/domain/resources/core/getMedias';
import { getMediasProgram as getMediasProgramCore } from '@/modules/medias/domain/resources/core/getMediasProgram';
import { getMedia as getMediaCorporate } from '@/modules/medias/domain/resources/corpo/getMedia';
import { getMedias as getMediasCorporate } from '@/modules/medias/domain/resources/corpo/getMedias';
import { getMediasProgram as getMediasProgramCorporate } from '@/modules/medias/domain/resources/corpo/getMediasProgram';
import { DomainTypes } from '@/types';
import {
  getSourceFromMediaKeyId,
  getUUIDFromMediaKeyId,
  getprogramUUIDFromMediaKeyId,
} from '@/utils';

export const getMediasProgram = async (programMediaKeyId: string) => {
  const source = getSourceFromMediaKeyId(programMediaKeyId);
  const programUUID = getUUIDFromMediaKeyId(programMediaKeyId);

  if (source === DomainTypes.Corporate && isAuthenticatedAsCorporate()) {
    return await getMediasProgramCorporate(programUUID);
  }

  return await getMediasProgramCore(programUUID);
};

export const getPlaybackUrl = async (mediaKeyId: string) => {
  const source = getSourceFromMediaKeyId(mediaKeyId);
  const mediaUUID = getUUIDFromMediaKeyId(mediaKeyId);

  if (source === DomainTypes.Corporate && isAuthenticatedAsCorporate()) {
    const media = await getMediaCorporate(mediaUUID);
    return media?.playbackURL;
  }

  /* const media = await getMediaCore(mediaUUID); */
  const programUUID = getprogramUUIDFromMediaKeyId(mediaKeyId);
  const programMedias = await getMediasProgramCore(programUUID);
  const media = (programMedias || []).find((media) => media.UUID === mediaUUID);

  return media?.playbackURL;
};

export const getMedias = async (mediaUUIDs = []) => {
  const coreMedias = await getMediasCore(mediaUUIDs);
  const corporateMedias = isAuthenticatedAsCorporate() ? await getMediasCorporate() : [];

  /** @todo: So far, we do not get specific programs from Corporate API */
  return [
    ...(coreMedias || []),
    ...(corporateMedias || []).filter(({ UUID }) => mediaUUIDs.includes(UUID)),
  ];
};

export const getMedia = async (mediaKeyId: string) => {
  const source = getSourceFromMediaKeyId(mediaKeyId);
  const mediaUUID = getUUIDFromMediaKeyId(mediaKeyId);

  if (source === DomainTypes.Corporate && isAuthenticatedAsCorporate()) {
    return await getMediaCorporate(mediaUUID);
  }
  /* return await getMediaCore(mediaUUID); */
  const coreMedias = await getMediasCore([mediaUUID]);

  return coreMedias?.[0];
};
